.animated.bounceIn {
  -webkit-animation-name: bounceIn;
  -moz-animation-name: bounceIn;
  -o-animation-name: bounceIn;
  animation-name: bounceIn;
}
@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes bounceInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateY(-30px);
  }
  80% {
    -moz-transform: translateY(10px);
  }
  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes bounceInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateY(-30px);
  }
  80% {
    -o-transform: translateY(10px);
  }
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    transform: translateY(-30px);
  }
  80% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.animated.bounceInUp {
  -webkit-animation-name: bounceInUp;
  -moz-animation-name: bounceInUp;
  -o-animation-name: bounceInUp;
  animation-name: bounceInUp;
}
@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateY(30px);
  }
  80% {
    -moz-transform: translateY(-10px);
  }
  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateY(30px);
  }
  80% {
    -o-transform: translateY(-10px);
  }
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateY(30px);
  }
  80% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.animated.bounceInDown {
  -webkit-animation-name: bounceInDown;
  -moz-animation-name: bounceInDown;
  -o-animation-name: bounceInDown;
  animation-name: bounceInDown;
}
@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(30px);
  }
  80% {
    -moz-transform: translateX(-10px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@-o-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateX(30px);
  }
  80% {
    -o-transform: translateX(-10px);
  }
  100% {
    -o-transform: translateX(0);
  }
}
@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(30px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
.animated.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  -moz-animation-name: bounceInLeft;
  -o-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}
@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes bounceInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(-30px);
  }
  80% {
    -moz-transform: translateX(10px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@-o-keyframes bounceInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateX(-30px);
  }
  80% {
    -o-transform: translateX(10px);
  }
  100% {
    -o-transform: translateX(0);
  }
}
@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(-30px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
.animated.bounceInRight {
  -webkit-animation-name: bounceInRight;
  -moz-animation-name: bounceInRight;
  -o-animation-name: bounceInRight;
  animation-name: bounceInRight;
}
@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
  }
  25% {
    -webkit-transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }
}
@-moz-keyframes bounceOut {
  0% {
    -moz-transform: scale(1);
  }
  25% {
    -moz-transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -moz-transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -moz-transform: scale(0.3);
  }
}
@-o-keyframes bounceOut {
  0% {
    -o-transform: scale(1);
  }
  25% {
    -o-transform: scale(0.95);
  }
  50% {
    opacity: 1;
    -o-transform: scale(1.1);
  }
  100% {
    opacity: 0;
    -o-transform: scale(0.3);
  }
}
@keyframes bounceOut {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(0.3);
  }
}
.animated.bounceOut {
  -webkit-animation-name: bounceOut;
  -moz-animation-name: bounceOut;
  -o-animation-name: bounceOut;
  animation-name: bounceOut;
}
@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}
@-moz-keyframes bounceOutUp {
  0% {
    -moz-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -moz-transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
}
@-o-keyframes bounceOutUp {
  0% {
    -o-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -o-transform: translateY(20px);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
}
@keyframes bounceOutUp {
  0% {
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}
.animated.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  -moz-animation-name: bounceOutUp;
  -o-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}
@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
}
@-moz-keyframes bounceOutDown {
  0% {
    -moz-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -moz-transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
}
@-o-keyframes bounceOutDown {
  0% {
    -o-transform: translateY(0);
  }
  20% {
    opacity: 1;
    -o-transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
}
@keyframes bounceOutDown {
  0% {
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}
.animated.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  -moz-animation-name: bounceOutDown;
  -o-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}
@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}
@-moz-keyframes bounceOutLeft {
  0% {
    -moz-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
}
@-o-keyframes bounceOutLeft {
  0% {
    -o-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -o-transform: translateX(20px);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
}
@keyframes bounceOutLeft {
  0% {
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    transform: translateX(20px);
  }
  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}
.animated.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  -moz-animation-name: bounceOutLeft;
  -o-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}
@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}
@-moz-keyframes bounceOutRight {
  0% {
    -moz-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
}
@-o-keyframes bounceOutRight {
  0% {
    -o-transform: translateX(0);
  }
  20% {
    opacity: 1;
    -o-transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
}
@keyframes bounceOutRight {
  0% {
    transform: translateX(0);
  }
  20% {
    opacity: 1;
    transform: translateX(-20px);
  }
  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}
.animated.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  -moz-animation-name: bounceOutRight;
  -o-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}
@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateIn {
  0% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateIn {
  0% {
    -o-transform-origin: center center;
    -o-transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    -o-transform-origin: center center;
    -o-transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateIn {
  0% {
    transform-origin: center center;
    transform: rotate(-200deg);
    opacity: 0;
  }
  100% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }
}
.animated.rotateIn {
  -webkit-animation-name: rotateIn;
  -moz-animation-name: rotateIn;
  -o-animation-name: rotateIn;
  animation-name: rotateIn;
}
@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInUpLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateInUpLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}
.animated.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  -moz-animation-name: rotateInUpLeft;
  -o-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}
@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInDownLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateInDownLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}
.animated.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  -moz-animation-name: rotateInDownLeft;
  -o-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}
@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInUpRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateInUpRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
}
.animated.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  -moz-animation-name: rotateInUpRight;
  -o-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}
@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}
@-moz-keyframes rotateInDownRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}
@-o-keyframes rotateInDownRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
}
.animated.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  -moz-animation-name: rotateInDownRight;
  -o-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}
@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(200deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOut {
  0% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(200deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOut {
  0% {
    -o-transform-origin: center center;
    -o-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -o-transform-origin: center center;
    -o-transform: rotate(200deg);
    opacity: 0;
  }
}
@keyframes rotateOut {
  0% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    transform-origin: center center;
    transform: rotate(200deg);
    opacity: 0;
  }
}
.animated.rotateOut {
  -webkit-animation-name: rotateOut;
  -moz-animation-name: rotateOut;
  -o-animation-name: rotateOut;
  animation-name: rotateOut;
}
@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutUpLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutUpLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
}
.animated.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  -moz-animation-name: rotateOutUpLeft;
  -o-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}
@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutDownLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutDownLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
}
.animated.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  -moz-animation-name: rotateOutDownLeft;
  -o-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}
@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutUpRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutUpRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }
}
@keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
}
.animated.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  -moz-animation-name: rotateOutUpRight;
  -o-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}
@-webkit-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }
}
@-moz-keyframes rotateOutDownRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }
}
@-o-keyframes rotateOutDownRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }
}
@keyframes rotateOutDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
  100% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
}
.animated.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  -moz-animation-name: rotateOutDownRight;
  -o-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}
@-webkit-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate(80deg);
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }
  40% {
    -webkit-transform: rotate(60deg);
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }
  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    opacity: 1;
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }
  100% {
    -webkit-transform: translateY(700px);
    opacity: 0;
  }
}
@-moz-keyframes hinge {
  0% {
    -moz-transform: rotate(0);
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -moz-transform: rotate(80deg);
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }
  40% {
    -moz-transform: rotate(60deg);
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }
  80% {
    -moz-transform: rotate(60deg) translateY(0);
    opacity: 1;
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }
  100% {
    -moz-transform: translateY(700px);
    opacity: 0;
  }
}
@-o-keyframes hinge {
  0% {
    -o-transform: rotate(0);
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -o-transform: rotate(80deg);
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }
  40% {
    -o-transform: rotate(60deg);
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }
  80% {
    -o-transform: rotate(60deg) translateY(0);
    opacity: 1;
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }
  100% {
    -o-transform: translateY(700px);
    opacity: 0;
  }
}
@keyframes hinge {
  0% {
    transform: rotate(0);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    transform: rotate(80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  40% {
    transform: rotate(60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  80% {
    transform: rotate(60deg) translateY(0);
    opacity: 1;
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: translateY(700px);
    opacity: 0;
  }
}
.animated.hinge {
  -webkit-animation-name: hinge;
  -moz-animation-name: hinge;
  -o-animation-name: hinge;
  animation-name: hinge;
}
@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
  }
}
@-moz-keyframes rollIn {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0px) rotate(0deg);
  }
}
@-o-keyframes rollIn {
  0% {
    opacity: 0;
    -o-transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0px) rotate(0deg);
  }
}
@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translateX(-100%) rotate(-120deg);
  }
  100% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }
}
.animated.rollIn {
  -webkit-animation-name: rollIn;
  -moz-animation-name: rollIn;
  -o-animation-name: rollIn;
  animation-name: rollIn;
}
@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
  }
}
@-moz-keyframes rollOut {
  0% {
    opacity: 1;
    -moz-transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    -moz-transform: translateX(100%) rotate(120deg);
  }
}
@-o-keyframes rollOut {
  0% {
    opacity: 1;
    -o-transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    -o-transform: translateX(100%) rotate(120deg);
  }
}
@keyframes rollOut {
  0% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }
  100% {
    opacity: 0;
    transform: translateX(100%) rotate(120deg);
  }
}
.animated.rollOut {
  -webkit-animation-name: rollOut;
  -moz-animation-name: rollOut;
  -o-animation-name: rollOut;
  animation-name: rollOut;
}
@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}
@-moz-keyframes lightSpeedIn {
  0% {
    -moz-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -moz-transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }
  80% {
    -moz-transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }
  100% {
    -moz-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}
@-o-keyframes lightSpeedIn {
  0% {
    -o-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -o-transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }
  80% {
    -o-transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }
  100% {
    -o-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}
@keyframes lightSpeedIn {
  0% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
  60% {
    transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }
  80% {
    transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }
  100% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}
.animated.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  -moz-animation-name: lightSpeedIn;
  -o-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  -moz-animation-timing-function: ease-out;
  -o-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}
.animated.lightSpeedIn {
  -webkit-animation-duration: .5s;
  -moz-animation-duration: .5s;
  -o-animation-duration: .5s;
  animation-duration: 0.5s;
}
@-webkit-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}
@-moz-keyframes lightSpeedOut {
  0% {
    -moz-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
  100% {
    -moz-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}
@-o-keyframes lightSpeedOut {
  0% {
    -o-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
  100% {
    -o-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}
@keyframes lightSpeedOut {
  0% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
  100% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}
.animated.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  -moz-animation-name: lightSpeedOut;
  -o-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  -moz-animation-timing-function: ease-in;
  -o-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}
.animated.lightSpeedOut {
  -webkit-animation-duration: .25s;
  -moz-animation-duration: .25s;
  -o-animation-duration: .25s;
  animation-duration: 0.25s;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity .15s linear;
  -moz-transition: opacity .15s linear;
  -o-transition: opacity .15s linear;
  transition: opacity 0.15s linear;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
/*
 * CSS TABLES
 */
.the-price {
  background-color: rgba(220, 220, 220, 0.17);
  box-shadow: 0 1px 0 #dcdcdc, inset 0 1px 0 #fff;
  padding: 20px;
  margin: 0;
}
.the-price h1 {
  line-height: 1em;
  padding: 0;
  margin: 0;
}
.subscript {
  font-size: 21px;
  color: #999;
}
/*
 * RIBBON
 */
.ribbon {
  position: absolute;
  right: 9px;
  top: -4px;
}
/* message reply */
.profile-message {
  background: none !important;
  height: auto !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  overflow: hidden;
}
.profile-message li.message.message-reply {
  margin-left: 85px !important;
  background: #F7F7F7;
  padding: 10px;
  margin: 3px 0 0 20px;
}
.profile-message li.message.message-reply img {
  width: 35px !important;
}
.profile-message li.message.message-reply .message-text {
  margin-left: 45px !important;
}
.profile-message .wall-comment-reply {
  margin-left: 85px;
  margin-bottom: 20px;
}
.chat-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(248, 248, 248, 0.9);
  padding: 0 10px 15px;
  position: relative;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.textarea-div {
  background-color: #FFF;
  border: 1px solid #CCC;
  border-bottom: none;
  margin: 10px 0 0;
}
.typearea {
  cursor: text;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 90px;
  padding: 5px 25px 5px 5px;
  position: relative;
  border-bottom: 1px solid #EEE;
}
.typearea textarea {
  width: 100%;
  border-color: transparent !important;
  box-sizing: border-box;
  box-shadow: none;
  -moz-box-shadow: none ;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-box-shadow: none;
  overflow: hidden;
  padding: 0;
  margin: 0;
  -webkit-transition: height 0.2s;
  -moz-transition: height 0.2s;
  transition: height 0.2s;
  outline: none;
  resize: none;
  min-height: 61px;
}
textarea.clone {
  position: absolute;
  visibility: hidden;
}
.textarea-controls {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 5px;
  display: block;
  background-color: #FFF;
  border: 1px solid #CCC;
  border-top: none;
  height: 43px;
  line-height: 33px;
  overflow: hidden;
}
.textarea-controls > label {
  margin: 3px 10px 0 10px;
}
.textarea-controls .smart-form .checkbox {
  padding-left: 22px !important;
}
@media (min-width: 768px) and (max-width: 880px) {
  .chat-body li.message .message-text .chat-file b {
    width: 150px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 479px) {
  .chat-body li.message .message-text .chat-file b {
    width: 150px;
  }
}
@media (max-width: 480px) {
  .chat-body li.message .message-text .chat-file b {
    width: 150px;
  }
}
/* end of fix */
/*
 * Error Page
 */
.error-box {
  max-width: 900px;
  margin: 0 auto;
}
.error-text {
  font-weight: 400;
  color: #ffffff;
  letter-spacing: -4px;
  font-size: 700%;
  margin-bottom: 30px;
  text-shadow: 0 1px 0 #cccccc, 0 2px 0 #bfbfbf, 0 3px 0 #bbbbbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaaaaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.15);
}
.error-search {
  width: 300px;
  margin: 0 auto 20px;
}
.error-icon-shadow {
  text-shadow: 0 1px 0 #803838, 0 2px 0 #a85d5d, 0 3px 0 #b86565, 0 4px 0 #d86f6f, 0 5px 0 #b3a5a5, 0 6px 1px rgba(131, 51, 51, 0.1), 0 0 5px rgba(102, 30, 30, 0.1), 0 1px 3px rgba(95, 15, 15, 0.3), 0 3px 5px rgba(100, 23, 23, 0.2), 0 5px 10px rgba(114, 30, 30, 0.25), 0 10px 10px rgba(94, 15, 15, 0.2), 0 20px 20px rgba(121, 38, 38, 0.15);
}
/*
 * LOGIN/REGISTER/EXTR PAGE
 */
.display-image {
  margin-top: -60px;
  margin-right: 20px;
}
.terms-body {
  max-height: 400px;
  overflow: auto;
}
/*
 * NESTABLES
 */
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 600px;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}
.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}
.dd-list .dd-list {
  padding-left: 30px;
}
.dd-collapsed .dd-list {
  display: none;
}
.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}
.dd-handle {
  display: block;
  font-size: 15px;
  margin: 5px 0;
  padding: 7px 15px;
  color: #333333;
  text-decoration: none;
  border: 1px solid #cfcfcf;
  background: #fbfbfb;
}
.dd-handle:hover {
  color: #2ea8e5;
  background: #ffffff;
}
.dd-item > button {
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 7px 10px;
  line-height: 22px !important;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: rgba(0, 0, 0, 0);
  text-align: center;
}
.dd-item > button {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}
.dd-item > button:before {
  content: '\f055';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #0091d9;
}
.dd-item > button[data-action="collapse"]:before {
  content: '\f056';
  color: #a90329;
}
.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #eeeeee;
  background-image: -webkit-linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff), -webkit-linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff);
  background-image: -moz-linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff), -moz-linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff);
  background-image: linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff), linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}
.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
}
.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}
.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}
/**
 * Nestable Extras
 */
.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #bfbfbf;
  border-bottom: 2px solid #bfbfbf;
}
#nestable-menu {
  padding: 0;
  margin: 10px 0 20px;
}
#nestable-output,
#nestable2-output {
  width: 100%;
  padding: 5px;
}
@media only screen and (min-width: 700px) {
  .dd {
    float: left;
    width: 100%;
  }
  .dd + .dd {
    margin-left: 2%;
  }
}
.dd-hover > .dd-handle {
  background: #2ea8e5 !important;
}
/**
 * Nestable Draggable Handles
 */
.dd3-content {
  display: block;
  margin: 5px 0;
  padding: 6px 10px 8px 40px;
  font-size: 15px;
  color: #333333;
  text-decoration: none;
  border: 1px solid #cfcfcf;
  background: #fbfbfb;
}
.dd3-content:hover {
  color: #2ea8e5;
  background: #ffffff;
}
.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}
.dd3-item > button {
  margin-left: 38px;
}
.dd3-handle {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  cursor: move;
  width: 30px;
  text-indent: 100%;
  color: transparent;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #aaa;
  background: #bfbfbf;
  background: -webkit-linear-gradient(top, #dddddd 0%, #bbbbbb 100%);
  background: -moz-linear-gradient(top, #dddddd 0%, #bbbbbb 100%);
  background: linear-gradient(top, #dddddd 0%, #bbbbbb 100%);
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  padding: 8px 15px;
}
.dd3-handle:before {
  content: '\f0c9';
  display: block;
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #818181;
  font-weight: normal;
  line-height: 28px;
}
.dd3-handle:hover:before {
  color: #C27616;
}
.dd-handle:hover + .dd-list .dd-handle,
.dd-handle:hover {
  background: #FDDFB3 !important;
  border: 1px solid #FAA937;
  color: #333 !important;
}
.dd-handle > span {
  font-size: 13px;
  color: #777;
}
.dd-dragel > .dd-item > .dd-handle {
  border-left-width: 4px;
}
.dd-dragel > li.dd-item.dd3-item {
  position: relative;
  top: 10px;
  left: 10px;
  z-index: 999;
}
/*
 * FULL CALENDAR
 */
#calendar-container {
  position: relative;
}
html .fc,
.fc table {
  font-size: 12px;
}
.dt-header.calender-spacer {
  height: 46px;
  display: block;
}
.fc-widget-header,
.fc-widget-content {
  border-right: none;
}
.fc-time,
.fc-title {
  padding: 3px 2px 2px 4px;
  line-height: 16px;
  font-weight: bold;
  font-size: 11px;
  box-sizing: border-box;
}
.fc-axis {
  font-weight: normal;
}
/* calendar button */
#calendar-buttons {
  position: absolute;
  right: 14px;
  top: 5px;
}
/* other month */
td.fc-other-month,
.dropzone {
  background-image: -webkit-gradient(linear, left top, right bottom, color-stop(0.25, rgba(0, 0, 0, 0.03)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(0, 0, 0, 0.03)), color-stop(0.75, rgba(0, 0, 0, 0.03)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(135deg, rgba(0, 0, 0, 0.03) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.03) 50%, rgba(0, 0, 0, 0.03) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(135deg, rgba(0, 0, 0, 0.03) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.03) 50%, rgba(0, 0, 0, 0.03) 75%, transparent 75%, transparent);
  background-image: -ms-linear-gradient(135deg, rgba(0, 0, 0, 0.03) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.03) 50%, rgba(0, 0, 0, 0.03) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(135deg, rgba(0, 0, 0, 0.03) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.03) 50%, rgba(0, 0, 0, 0.03) 75%, transparent 75%, transparent);
  background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.03) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.03) 50%, rgba(0, 0, 0, 0.03) 75%, transparent 75%, transparent);
  background-color: #FAFCFD;
  background-size: 16px 16px;
}
.fc-corner-right .fc-event-inner {
  padding-right: 15px;
}
/*
  * Select color
  */
.btn-select-tick i {
  display: none;
}
.btn-select-tick .btn:hover i {
  opacity: .3;
  display: block;
}
.btn-select-tick .active i {
  display: block;
  opacity: 1 !important;
}
/*
  * Drag and drop events
  */
#external-events > li {
  margin: 6px 4px 6px 0;
  display: inline-block;
}
#external-events > li > :first-child {
  padding: 5px 10px 10px;
  cursor: move;
  display: block;
}
#external-events > li > :first-child:after {
  color: #ffffff;
  color: rgba(255, 255, 255, 0.7);
  content: attr(data-description);
  font-size: 11px;
  font-weight: 400;
  display: block;
  line-height: 0;
  margin: 7px 0;
  text-transform: lowercase;
}
/*
 * PROFILE PAGE
 */
.profile-pic {
  text-align: right;
}
.profile-pic > img {
  border-radius: 0%;
  position: relative;
  border: 5px solid #ffffff;
  top: -30px;
  left: 10px;
  display: inline-block;
  text-align: right;
  z-index: 4;
  max-width: 100px;
  margin-bottom: -30px;
}
.friends-list li {
  margin-bottom: 10px;
  margin-right: 3px;
  padding: 0;
}
.friends-list img {
  width: 35px;
  border: 1px solid #ffffff;
  outline: 1px solid #bfbfbf;
}
.profile-link-btn {
  color: #999999;
  font-size: 14px;
}
/*
 * Calendar Icon
 */
time.icon {
  font-size: 14px;
  /* change icon size */
  display: block;
  position: relative;
  width: 35px;
  height: 43px;
  background-color: #fff;
  margin: 3px auto;
  border-radius: 7px;
  box-shadow: 0 1px 0 #bdbdbd,0 2px 0 #fff,0 3px 0 #EEE9E9,0 4px 0 #fff,0 5px 0 #FFFFFF,0 0 0 1px #D3D3D3;
  overflow: hidden;
}
time.icon * {
  display: block;
  width: 100%;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  text-align: center;
}
time.icon strong {
  position: absolute;
  top: 0;
  padding: 2px 0;
  color: #fff;
  background-color: #3a3633;
  -webkit-box-shadow: 0 2px 0 #3a3633;
  box-shadow: 0 2px 0 #3a3633;
  border-radius: 6px 6px 0 0;
}
time.icon em {
  position: absolute;
  bottom: 3px;
  color: #3276b1;
}
time.icon span {
  width: 100%;
  font-size: 15px;
  letter-spacing: -0.05em;
  padding-top: 21px;
  color: #2f2f2f;
}
/*
 * New users
 */
.user {
  display: block;
  float: left;
  width: 170px;
  margin: 0 15px 15px 0;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user img {
  float: left;
  width: 35px;
  margin-right: 5px;
}
.user .email {
  color: #999;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*
 * SEARCH RESULTS
 */
.search-results {
  padding: 18px 5px;
}
.search-results + .search-results {
  border-top: 1px dashed #E3E3E3;
}
.search-results > :first-child {
  margin-bottom: 4px;
  font-weight: 400;
}
.search-results > :first-child a {
  text-decoration: underline;
}
.search-results .url {
  font-style: normal;
  font-size: 14px;
}
.search-results img {
  display: inline-block;
  margin-top: 4px;
  margin-right: 4px;
  width: 80px;
}
.search-results > div {
  display: inline-block;
  vertical-align: top;
}
.search-results .note {
  margin: 0;
  line-height: normal;
}
.search-results .note a {
  text-decoration: none !important;
  color: #333333;
}
.search-results .note a:hover {
  color: #ed1c24;
}
/*
 * TODO's
 */
.todo-group-title {
  margin: 0;
  line-height: 31px;
  padding: 0 0 0 10px;
  background: #fafafa;
  border-bottom: 1px solid #e7e7e7;
  border-top: 1px solid #f4f4f4;
  color: #999999;
}
.todo {
  margin: 0;
  padding: 0;
  min-height: 5px;
  list-style: none;
}
.todo > li {
  display: block;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #e7e7e7;
  margin: 0 5px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAQCAYAAADagWXwAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0RkQ1OEY4NTM4NUIxMUUzQjdCMUMxQzJCQUE3MTMxOCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0RkQ1OEY4NjM4NUIxMUUzQjdCMUMxQzJCQUE3MTMxOCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjRGRDU4RjgzMzg1QjExRTNCN0IxQzFDMkJBQTcxMzE4IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjRGRDU4Rjg0Mzg1QjExRTNCN0IxQzFDMkJBQTcxMzE4Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+pTD+wgAAACtJREFUeNpimDBhwn8gYABhdDYjiIELMDHgAUwTJ06Ea0VnjxpLO2MBAgwAGYZLegQbQ3UAAAAASUVORK5CYII=) 1px 11px no-repeat;
}
.todo > li:last-child,
.todo > li:only-child {
  border-bottom: none;
}
.todo > li > :first-child {
  display: block;
  border-right: 1px solid #FFE1EB;
  height: 100%;
  padding: 6px 11px 6px 18px;
  width: 20px;
  vertical-align: top;
  position: absolute;
}
.todo > li > :first-child:hover {
  cursor: move;
}
.todo > li.complete > :first-child:hover {
  cursor: default;
}
.todo > li.complete {
  background: none;
}
.todo > li > p {
  height: 100%;
  margin-left: 52px;
  border-left: 1px solid #FFE1EB;
  display: inline-block;
  padding: 8px 0px 6px 7px;
  margin-bottom: 0;
  min-height: 37px;
  line-height: normal;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
}
.todo > li > p > span {
  display: block;
  line-height: 12px;
  font-size: 10px;
  font-weight: normal;
}
.todo > li > p > :first-child {
  margin-top: -5px;
  color: #999999;
  margin-bottom: 4px;
}
.todo > li > p > .date {
  color: #bfbfbf;
}
.todo > li.complete > * {
  text-decoration: line-through;
  font-style: italic;
}
/* drag */
.todo > li.ui-sortable-helper {
  border-top: 1px solid #eeeeee;
  background: rgba(113, 132, 63, 0.1);
}
/*
 * TIMELINE PAGE
 */
/* Timeline */
.smart-timeline {
  position: relative;
}
.smart-timeline-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.smart-timeline-list:after {
  content: " ";
  background-color: #eeeeee;
  position: absolute;
  display: block;
  width: 2px;
  top: 0;
  left: 95px;
  bottom: 0;
  z-index: 1;
}
.smart-timeline-list li {
  position: relative;
  margin: 0;
  padding: 15px 0;
}
.smart-timeline-list > li:hover {
  background-color: #f4f4f4;
}
.smart-timeline-hover li:hover {
  background-color: #f9f9f9;
}
.smart-timeline-icon {
  background: #3276b1;
  color: #ffffff;
  border-radius: 50%;
  position: absolute;
  width: 32px;
  height: 32px;
  line-height: 28px;
  font-size: 14px;
  text-align: center;
  left: 80px;
  top: 10px;
  z-index: 100;
  padding: 2px;
}
.smart-timeline-icon > img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-top: -2px;
  margin-left: -2px;
  border: 2px solid #3276b1;
}
.smart-timeline-time {
  float: left;
  width: 70px;
  text-align: right;
}
.smart-timeline-time > small {
  font-style: italic;
}
.smart-timeline-content {
  margin-left: 123px;
}
/*
 * FORUM PAGE
 */
.table-forum thead tr {
  background: none !important;
}
.table-forum thead tr th {
  font-size: 17px;
  font-weight: normal;
  border-bottom-color: #F1F1F1;
}
.table-forum tr td {
  border-top-style: dashed !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.table-forum tr td h4 {
  font-size: 15px;
  font-weight: 700;
}
.table-forum tr td h4 small {
  font-size: 13px;
  display: block;
  margin-top: 2px;
}
.table-forum tr td > i {
  margin-top: 4px;
  padding-left: 10px;
}
.table-forum tr.locked h4 > a:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f023";
}
.table-forum tr.closed h4 > a {
  text-decoration: line-through;
  color: #848484;
  cursor: not-allowed;
}
.forum-attachment {
  margin-top: 20px;
  display: block;
}
.ajax-loading-error {
  margin-top: 70px;
  display: block;
  text-align: center;
  font-size: 23px;
  font-weight: bold;
}
.ajax-notifications .ajax-loading-error {
  text-align: center;
  margin-top: 20px;
  font-size: 15px;
}
.ajax-notifications .ajax-loading-animation {
  font-size: 15px;
  text-align: center;
  margin-top: 70px;
}
.project-members a {
  display: inline-block;
  overflow: hidden;
  margin: 0 3px 0 0;
  border-radius: 0px;
}
.smart-rtl.project-members a {
  margin: 0 0 0 3px;
}
.project-members img {
  width: 25px;
  border-radius: 0px;
}
.projects-table > tbody > tr > td {
  padding: 7px 10px 3px !important;
}
.status .who {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  padding: 20px;
}
.status .who img {
  float: left;
  height: 40px;
  margin-right: 10px;
  width: 40px;
}
.status .who .name {
  display: block;
  font-size: 13px;
  font-weight: normal;
  line-height: normal;
}
.status .who .name b {
  color: #57889c;
}
.status .who .from {
  font-size: 12px;
  opacity: 0.7;
}
.status .text {
  font-size: 13px;
  line-height: 1.82em;
  padding: 20px;
}
.status .image {
  padding: 20px;
}
.status .image img {
  width: 100%;
}
.status .links {
  border-top: 1px solid rgba(0, 0, 0, 0.07);
  margin: 0;
  padding: 15px 20px;
  background: #fafafa;
}
.status .links .fa {
  color: inherit;
  font-size: 14px;
  margin-right: 2px;
}
.status .links li {
  display: inline;
  padding-right: 20px;
}
.status .comments {
  border-top: 1px solid rgba(0, 0, 0, 0.07);
  font-size: 13px;
  padding: 0 20px;
}
.status .comments li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  display: block;
  padding: 15px 0 15px 40px;
  position: relative;
}
.status .comments li:last-child {
  border-bottom: none;
  padding-bottom: 5px;
}
.status .comments img {
  height: 30px;
  left: 0px;
  position: absolute;
  width: 30px;
}
.status .comments .name {
  display: block;
  font-weight: 600;
  opacity: 0.8;
}
.status.panel-body {
  padding: 0px;
}
.well.connect {
  padding: 14px;
}
.well.connect img {
  border-radius: 50% 50% 0;
}
.status.vote .comments {
  padding: 0px !important;
}
.status.vote .comments li {
  padding: 10px 0 10px 20px !important;
}
.status.vote .comments li:last-child {
  padding-right: 20px !important;
}
blockquote.twitter-tweet {
  border-color: #eeeeee #dddddd #bbbbbb;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  display: inline-block;
  font-family: "Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin: 10px 5px;
  width: calc(100% - 10px);
  padding: 0 16px 16px 16px;
}
blockquote.twitter-tweet p {
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
}
blockquote.twitter-tweet a {
  color: inherit;
  font-weight: normal;
  outline: 0 none;
  text-decoration: none;
}
blockquote.twitter-tweet a:hover {
  text-decoration: underline;
}
blockquote.twitter-tweet a:focus {
  text-decoration: underline;
}
/*.product-view {
  min-height: 380px;

  &> img {
    width:100%;
    height: auto;
    background: fade(#333,10%);
  }

  .price {
    position: absolute;
      right: 10px;
      margin-top: -35px;
      padding: 14px 15px 14px 19px;
      background: #ed1c24;
      color: #FFF;
      border-radius: 50%;
      font-size: 25px;
      font-weight: 300;

      &>b {
        font-weight: 300;
      position: absolute;
      margin-top: 1px;
        font-size: 16px;
      left: 10px;
      }
  }

  h2 {
    font-size:18px;

  }

  p {
    font-size: 12px;
    margin-top:3px;
    width:90%;
    width: calc(~"100% - 10px");
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}*/
.product-content {
  border: 1px solid #dfe5e9;
  margin-bottom: 20px;
  margin-top: 12px;
  background: #ffffff;
}
.product-content .product-image {
  background-color: #ffffff;
  display: block;
  min-height: 238px;
  overflow: hidden;
  position: relative;
}
.product-content .product-deatil {
  border-bottom: 1px solid #dfe5e9;
  padding-bottom: 17px;
  padding-left: 16px;
  padding-top: 16px;
  position: relative;
  background: #ffffff;
}
.product-content .product-deatil h5 a {
  color: #2f383d;
  font-size: 15px;
  line-height: 19px;
  text-decoration: none;
  padding-left: 0px;
  margin-left: 0px;
}
.product-content .product-deatil h5 a span {
  color: #9aa7af;
  display: block;
  font-size: 13px;
}
.product-content .product-deatil p.price-container span {
  line-height: 15px;
}
.product-content .product-deatil span.tag1 {
  border-radius: 50%;
  color: #ffffff;
  font-size: 15px;
  height: 50px;
  padding: 13px 0px;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 10px;
  width: 50px;
}
.product-content .product-deatil span.sale {
  background-color: #21c2f8;
}
.product-content .product-deatil span.discount {
  background-color: #71e134;
}
.product-content .product-deatil span.hot {
  background-color: #fa9442;
}
.product-content .description {
  font-size: 12.5px;
  line-height: 20px;
  padding: 10px 14px 16px 19px;
  background: #ffffff;
}
.product-content .product-info {
  padding: 11px 19px 10px 20px;
}
.product-content .product-info a.add-to-cart {
  color: #2f383d;
  font-size: 13px;
  padding-left: 16px;
}
.product-content name.a {
  padding: 5px 10px;
  margin-left: 16px;
}
.product-info.smart-form .btn {
  padding: 6px 12px;
  margin-left: 12px;
  margin-top: -10px;
}
.product-entry .product-deatil {
  border-bottom: 1px solid #dfe5e9;
  padding-bottom: 17px;
  padding-left: 16px;
  padding-top: 16px;
  position: relative;
}
.product-entry .product-deatil h5 a {
  color: #2f383d;
  font-size: 15px;
  line-height: 19px;
  text-decoration: none;
}
.product-entry .product-deatil h5 a span {
  color: #9aa7af;
  display: block;
  font-size: 13px;
}
.product-entry .product-deatil p.price-container span {
  line-height: 15px;
}
.load-more-btn {
  background-color: #21c2f8;
  border-bottom: 2px solid #037ca5;
  border-radius: 2px;
  border-top: 2px solid #00ccff;
  margin-top: 20px;
  padding: 9px 0px;
  width: 100%;
}
.product-block .product-deatil p.price-container span,
.product-content .product-deatil p.price-container span,
.product-entry .product-deatil p.price-container span,
.shopping-items table tbody tr td p.price-container span,
.shipping table tbody tr td p.price-container span {
  color: #21c2f8;
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  line-height: 20px;
}
.product-info.smart-form .rating label {
  margin-top: 0px;
}
.product-wrap .product-image span.tag2 {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  padding: 10px 0px;
  color: #fff;
  font-size: 11px;
  text-align: center;
}
.product-wrap .product-image span.sale {
  background-color: #57889c;
}
.product-wrap .product-image span.hot {
  background-color: #a90329;
}
.shop-btn {
  position: relative;
}
.shop-btn > span {
  background: #a90329;
  display: inline-block;
  font-size: 10px;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  font-weight: bold;
  border-radius: 50%;
  padding: 2px 4px 3px 4px !important;
  text-align: center;
  line-height: normal;
  width: 19px;
  top: -7px;
  left: -7px;
}
.description-tabs {
  padding: 30px 0px 5px 0px !important;
}
.description-tabs .tab-content {
  padding: 10px 0px;
}
.product-deatil {
  padding: 30px 30px 50px 30px;
}
.product-deatil hr + .description-tabs {
  padding: 0px 0px 5px 0px !important;
}
.product-deatil .glyphicon {
  color: #3276b1;
}
.product-deatil .product-image {
  border-right: none !important;
}
.product-deatil .name {
  margin-top: 0px;
  margin-bottom: 0px;
}
.product-deatil .name small {
  display: block;
}
.product-deatil .name a {
  margin-left: 0px;
}
.product-deatil .price-container {
  font-size: 24px;
  margin: 0px;
  font-weight: 300;
}
.product-deatil .price-container small {
  font-size: 12px;
}
.product-deatil .fa-2x {
  font-size: 16px !important;
}
.product-deatil .fa-2x > h5 {
  font-size: 12px;
  margin: 0px;
}
.product-deatil .fa-2x + a,
.product-deatil .fa-2x + a + a {
  font-size: 13px;
}
.product-deatil .certified {
  margin-top: 10px;
}
.product-deatil .certified ul {
  padding-left: 0px;
}
.product-deatil .certified ul li:not(first-child) {
  margin-left: -3px;
}
.product-deatil .certified ul li {
  display: inline-block;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 13px 19px;
}
.product-deatil .certified ul li:first-child {
  border-right: none;
}
.product-deatil .certified ul li a {
  text-align: left;
  font-size: 12px;
  color: #6d7a83;
  line-height: 16px;
  text-decoration: none;
}
.product-deatil .certified ul li a span {
  display: block;
  color: #21c2f8;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
}
.product-deatil .message-text {
  width: calc(100% - 70px);
}
@media only screen and (min-width: 1024px) {
  .product-content .product-image {
    border-right: 1px solid #dfe5e9;
    margin-right: -13px;
  }
  .product-content .product-info {
    position: relative;
  }
  .product-content .product-info,
  .product-content .description,
  .product-content .product-deatil {
    margin-left: -13px;
  }
  .product-deatil.product-content .product-info,
  .product-deatil.product-content .description,
  .product-deatil.product-content .description {
    margin-left: 0;
  }
}
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
}

body {
    background: url('./mybg.png') #ffffff;
    direction: ltr;
}
/* when mobile menu is active */
body.mobile-view-activated.hidden-menu {
  overflow-x: hidden;
}
body.modal-open {
  overflow: hidden !important;
}
a:hover,
a:active,
a:focus,
button,
button:active,
button:focus,
object,
embed,
input::-moz-focus-inner {
  outline: 0;
}
h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: "Open Sans", Arial, Helvetica, Sans-Serif;
  font-weight: 300;
}
.page-title {
  margin: 12px 0 28px;
}
.page-title span {
  font-size: 16px;
  color: #333333;
  display: inline-block;
  vertical-align: 1px;
}
label {
  font-weight: normal;
}
*:focus {
  outline: 0 !important;
}
/*
 * IE FIX for tap delay
 */
a,
input,
button {
  -ms-touch-action: none !important;
}
textarea:focus,
select:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  outline: 0;
  outline: thin dotted \9;
  /* IE6-9 */
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.input-sm,
.input-lg,
.input-xs,
.form-control {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
}
.input-xs {
  height: 24px;
  padding: 2px 10px;
  font-size: 11px;
  line-height: 1.5;
}
.btn-xs {
  padding: 1px 5px;
}
.btn-sm {
  padding: 6px 10px 5px;
}
.btn-lg {
  padding: 10px 16px;
}
.no-space {
  margin: 0;
}
.no-space > [class*="col-"] {
  margin: 0 !important;
  padding-right: 0;
  padding-left: 0;
}
#content {
  padding: 10px 14px;
  position: relative;
  /*position:relative;
   left:0px;*/
}
body.container {
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  border-left: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
  box-shadow: 0 2px 70px rgba(0, 0, 0, 0.45);
}
body.container.hidden-menu:not(.mobile-view-activated) {
  overflow: hidden;
}
body.container.modal-open {
  padding: 0px !important;
}
/*
 * Headers
 */
h1 {
  letter-spacing: -1px;
  font-size: 24px;
  margin: 10px 0;
}
h1 small {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -1px;
}
.lead {
  font-size: 19px;
}
h2 {
  letter-spacing: -1px;
  font-size: 22px;
  margin: 20px 0;
  line-height: normal;
}
h3 {
  display: block;
  font-size: 19px;
  font-weight: 400;
  margin: 20px 0;
  line-height: normal;
}
h4 {
  line-height: normal;
}
h5 {
  font-size: 17px;
  font-weight: 300;
  margin: 10px 0;
  line-height: normal;
}
h6 {
  font-size: 15px;
  margin: 10px 0;
  font-weight: bold;
  line-height: normal;
}
.row-seperator-header {
  margin: 15px 14px 20px;
  border-bottom: none;
  display: block;
  color: #646464;
  font-size: 20px;
  font-weight: 400;
}
/*
 * Footer
 * see: main-page-footer.less
 */
/*
 * Center Canvas
 */
.center-canvas,
.center-child-canvas > canvas {
  display: block !important;
  margin: 0 auto !important;
}
/*
 * JUST A BORDER
 */
.bordered {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.bordered:hover {
  border: 1px solid rgba(0, 0, 0, 0.4);
}
/*
 * ***************************************************
 * MAIN LAYOUT START
 * ***************************************************
 */
aside {
  display: block;
  /*overflow: hidden;*/
  background: #3a3633;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSI5NyUiIHN0b3AtY29sb3I9IiMzYTM2MzMiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMmEyNzI1IiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==");
  background: -moz-linear-gradient(left, #3a3633 93%, #2a2725 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(93%, #3a3633), color-stop(100%, #2a2725));
  background: -webkit-linear-gradient(left, #3a3633 93%, #2a2725 100%);
  background: -o-linear-gradient(left, #3a3633 93%, #2a2725 100%);
  background: -ms-linear-gradient(left, #3a3633 93%, #2a2725 100%);
  background: linear-gradient(to right, #3a3633 93%, #2a2725 100%);
  min-height: 100%;
}
/*
 * HEADER GROUP
 */
#header {
  display: block;
  height: 49px;
  margin: 0;
  padding: 0 13px 0 0;
  background-color: #f3f3f3;
  background-image: -moz-linear-gradient(top, #f3f3f3, #e2e2e2);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3f3f3), to(#e2e2e2));
  background-image: -webkit-linear-gradient(top, #f3f3f3, #e2e2e2);
  background-image: -o-linear-gradient(top, #f3f3f3, #e2e2e2);
  background-image: linear-gradient(to bottom, #f3f3f3, #e2e2e2);
  background-repeat: repeat-x;
  position: relative;
  z-index: 905;
}
#header > div {
  display: inline-block;
  vertical-align: middle;
  height: 49px;
  float: left;
}
#header > div.open {
  font-weight: bold;
}
.fixed-header #header {
  position: fixed;
  width: 100%;
  z-index: 905;
  top: 0px;
  border-bottom: 2px solid #999999;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: -11px 12px 23px rgba(0, 0, 0, 0.1);
}
/* as per version 1.3 */
.fixed-header.fixed-ribbon #header {
  border-bottom: none;
  box-shadow: none;
}
.container.fixed-header #header {
  max-width: 1164px;
}
.fixed-header #header .ajax-dropdown {
  z-index: 906;
}
.fixed-header #shortcut {
  position: fixed;
}
.fixed-header #main {
  margin-top: 49px;
}
#logo-group > span {
  display: inline-block;
  height: 39px;
  float: left;
}
#logo-group span#activity {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  cursor: default !important;
  display: inline-block;
  font-weight: bold;
  height: 24px;
  width: 24px;
  padding: 2px;
  text-align: center;
  text-decoration: none !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  background-color: #f8f8f8;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f8f8f8), to(#f1f1f1));
  background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -moz-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -ms-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -o-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: linear-gradient(top, #f8f8f8, #f1f1f1);
  border: 1px solid #bfbfbf;
  color: #c4bab6;
  font-size: 19px;
  margin: 10px 0 0 0;
  position: relative;
}
#logo-group span#activity:hover {
  border: 1px solid #bfbfbf;
  transition: all 0.0s;
  cursor: pointer !important;
  -webkit-box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
}
#logo {
  display: inline-block;
  width: 175px;
  margin-top: 13px;
  margin-left: 9px;
}
#logo img {
  width: 110px;
  height: auto;
  padding-left: 3px;
}
#activity b.badge {
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  background: #0091d9;
  display: inline-block;
  font-size: 10px;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  color: #ffffff;
  font-weight: bold;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  padding: 2px 4px 3px 4px;
  text-align: center;
  line-height: normal;
}
#activity.active .badge {
  background: #006fa6 !important;
}
#project-context,
.project-context {
  display: inline-block;
  padding: 7px 13px 0;
  position: relative;
}
#project-context > span,
.project-context > span {
  display: block;
}
#project-context > :first-child,
.project-context > :first-child {
  display: block;
  color: #bfbfbf;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 0 0 1px white;
  padding-left: 0px;
  text-align: left;
}
#project-selector,
.project-selector {
  max-width: 97%;
  white-space: nowrap;
  background: none;
  border: none;
  padding: 0;
  text-align: left;
  color: #555555;
  font-size: 14px;
  cursor: pointer;
}
#project-selector:hover,
.project-selector:hover {
  color: #333333;
}
.header-search {
  position: relative;
}
.header-search.pull-right {
  margin-left: 6px;
}
.header-search > input[type="text"] {
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  min-width: 200px;
  width: 100%;
  height: 30px;
  padding: 0 10px;
  outline: none;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  border-color: #bfbfbf;
  background: #ffffff;
  color: #404040;
  appearance: normal;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin-top: 10px;
  line-height: normal;
}
.header-search > button {
  background: none;
  border: none;
  color: #6D6A69;
  font-size: 17px;
  height: 30px;
  line-height: 30px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0px;
  top: 10px;
  width: 30px;
  z-index: 2;
}
#search-mobile {
  display: none;
}
#cancel-search-js {
  position: absolute;
  display: none;
  background: #a90329;
  z-index: 3;
  color: #ffffff;
  padding: 0;
  right: 10px;
  top: 10px;
  text-align: center;
  height: 29px;
  line-height: 29px;
  width: 30px;
  font-size: 17px;
  text-decoration: none !important;
}
#header > :first-child,
aside {
  width: 220px;
}
/*
 * LEFT PANEL
 */
#left-panel {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 904;
  padding-top: 49px;
}
/*
 * MAIN PANEL
 */
#main {
  margin-left: 220px;
  padding: 0;
  padding-bottom: 52px;
  min-height: 500px;
  position: relative;
}
/*
 * RIBBON
 */
#ribbon {
  min-height: 40px;
  background: #474544;
  padding: 0 13px;
  position: relative;
}
#ribbon .breadcrumb {
  display: inline-block;
  margin: 0;
  padding: 11px 34px 11px 0 !important;
  background: none;
  vertical-align: top;
}
#ribbon .breadcrumb a,
#ribbon .breadcrumb {
  color: #BBB !important;
  text-decoration: none !Important;
}
#ribbon .breadcrumb > .active,
#ribbon .breadcrumb li:last-child {
  color: #E4E4E4;
}
.fixed-ribbon #ribbon {
  position: fixed;
  right: 0;
  left: 220px;
  top: 49px;
  z-index: 901;
}
.container.fixed-ribbon #ribbon {
  width: 944px;
  left: 590px;
}
.fixed-ribbon #content {
  padding-top: 50px;
}
.minified.fixed-ribbon #ribbon {
  left: 45px;
}
.hidden-menu.fixed-ribbon #ribbon {
  left: 10px;
}
/*
 * NAV
 * Menu CSS is learnt from: http://experiments.wemakesites.net/css3-treeview.html
 */
.fixed-navigation #left-panel {
  height: 100%;
  position: fixed;
  /*z-index: @fixed-navigation-zindex; */
}
/* Add this if not using slimscroll*/
.fixed-navigation nav {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  height: 100%;
  padding-bottom: 49px;
}
.fixed-navigation nav > ul {
  padding-right: 15px;
  position: relative;
  height: 100%;
  width: 114%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.mobile-detected.fixed-navigation nav > ul {
  padding-right: 30px !important;
}
/* Slimscrol CSS change: delete this if not using slimscroll

 .fixed-navigation nav {
 padding-bottom: 49px;
 }

 .fixed-navigation nav > ul {
 position: relative;
 }

 .slimScrollBar,
 .slimScrollRail {
 display:none !important;
 }

 .fixed-navigation .slimScrollBar,
 .fixed-navigation .slimScrollRail {
 display:block !important;
 }

 .minified .slimScrollBar {
 display:none !important;
 }*/
/* end slimscroll css change*/
.minified.fixed-navigation nav {
  -webkit-overflow-scrolling: none;
  overflow: visible;
  height: auto;
  padding-bottom: 0px;
}
.minified.fixed-navigation nav > ul {
  overflow: visible;
  padding-right: 0px;
  width: 100%;
}
/* All levels */
nav ul li a:active {
  background: #616161 !important;
}
/* All levels */
nav ul {
  width: 100%;
  padding: 40px 28px 25px 0;
  padding: 0;
  margin: 0;
  font-size: 13px;
  line-height: 0.5em;
  list-style: none;
  position: relative;
}
/* All levels */
nav ul .active > a {
  color: #fff !important;
  position: relative;
}
/* All levels */
nav ul li.active > a:before {
  content: "\f0d9";
  font-family: FontAwesome;
  display: block;
  height: 27px;
  line-height: normal;
  width: 27px;
  position: absolute;
  right: -21px;
  font-size: 20px;
  color: #eeeeee;
}
nav ul li.active.open > a:before {
  content: "";
}
nav > ul > li:last-child.open {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
/* All levels
nav ul li {
  overflow: hidden;
}*/
/* All levels */
nav ul li a {
  line-height: normal;
  font-size: 14px;
  padding: 10px 10px 10px 11px;
  color: #c0bbb7;
  display: block;
  font-weight: normal;
  text-decoration: none !important;
  position: relative;
}
/* Second level */
nav > ul > li > ul > li > a {
  padding-top: 7px;
  padding-bottom: 7px;
}
/* Third Level */
nav > ul > li > ul > li > ul > li > a {
  padding-top: 7px;
  padding-bottom: 7px;
}
/* All levels */
nav ul li a:focus {
  color: #c9c9c9;
}
/* All levels */
nav ul li a:hover {
  color: #ffffff;
  text-decoration: none;
}
nav ul li a.inactive,
nav ul li a.inactive:hover {
  opacity: .5;
  cursor: not-allowed;
  color: #c0bbb7;
}
/* All levels */
nav ul b {
  float: right;
  font-size: 11px;
  margin-top: 2px;
}
/* All levels */
nav ul span.menu-item-parent {
  display: inline-block;
  margin: 0;
  padding: 0;
}
/* First level: A, Second level: LI Open */
nav ul li.open > a,
nav ul li.open > a b {
  color: #fff !important;
}
/* First level: Icon */
nav > ul > li > a b {
  position: absolute !important;
  right: 10px;
  top: 10px;
}
nav > ul > li > a > i {
  /*font-size: 121%; fa-lg overrides this so..  */
  margin-right: 5px;
  width: 15px;
  display: inline-block;
  text-align: center;
  position: relative;
}
/* First level icon */
nav > ul > li > a > i > em {
  font-size: 9px;
  display: block;
  padding: 2px;
  position: absolute;
  top: -8px;
  right: -6px;
  text-decoration: none;
  font-style: normal;
  background: #ED1C24;
  color: #ffffff;
  min-width: 13px;
  border-radius: 50%;
  max-height: 13px;
  line-height: 8px;
  font-weight: bold;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
/* Second level switched to relative */
nav ul li li {
  border-bottom: none;
  position: relative;
}
/* Second level: UL */
nav > ul > li > ul::before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 23px;
  top: 0;
  bottom: 0;
  border-left: 1px solid #7A7A7A;
}
/* Second level: UL LI */
nav > ul ul li::before {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  left: 23px;
  top: 16px;
  border-top: 1px solid #7A7A7A;
  z-index: 1;
}
/* Third level: UL LI */
nav > ul ul ul li::before {
  content: "";
  display: block;
  position: absolute;
  width: 18px;
  left: 10px;
  top: 17px;
  border-top: 1px solid transparent;
}
/* Second level and onwards > */
nav > ul ul li a i {
  font-size: 14px !important;
  width: 18px !important;
  text-align: center !important;
  vertical-align: 0 !important;
  line-height: 1 !important;
}
/* Second and Third level line color */
nav > ul > li:hover > ul::before,
nav > ul > li:hover > ul > li::before {
  border-color: #ACACAC !important;
}
/* Second Level: UL */
nav ul ul {
  margin: 0;
  padding: 0;
  display: none;
  background: rgba(69, 69, 69, 0.6);
}
nav ul ul {
  padding: 7px 0;
}
/* Third Level: UL */
nav ul ul ul {
  background: transparent;
  padding: 0;
}
/* Third Level: LI */
nav ul ul li {
  margin: 0;
  padding: 0;
}
/* Second Level: A */
nav ul ul li > a {
  padding-left: 42px;
  font-size: 14px;
  font-weight: normal;
  outline: 0;
}
/* Second Level: A:hover */
nav ul ul li > a:hover {
  background-color: rgba(69, 69, 69, 0.8);
  color: #ffffff;
}
/* Third Level: A */
nav ul ul ul li a {
  color: #bfbfbf;
  padding: 8px 10px 8px 60px;
  font-size: 14px;
}
/* Third Level: A:hover */
nav ul ul ul li a:hover {
  color: #ffffff;
}
/* Fourth Level: A */
nav ul ul ul ul li a {
  padding-left: 80px;
}
/* Fifth Level: A */
nav ul ul ul ul ul li a {
  padding-left: 100px;
}
/* Sixth Level: A */
nav ul ul ul ul ul ul li a {
  padding-left: 120px;
}
/* Second level: B */
nav ul ul b {
  color: #B3B3B3;
}
/* Second level: LI:hover B */
nav ul ul > li:hover b {
  color: #D5D9E2;
}
/*
 * HEADER NAV DROPDOWN LIST
 */
.header-dropdown-list {
  list-style: none;
  float: right;
  position: relative;
  margin: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
.header-dropdown-list > li {
  display: inline-block;
  padding-right: 5px;
}
.header-dropdown-list > li > .dropdown-toggle {
  margin-top: 16px;
  display: block;
}
/*
 * ***************************************************
 * MAIN LAYOUT END
 * ***************************************************
 */
/*
 * ACCORDIONS
 */
.smart-accordion-default.panel-group {
  margin-bottom: 0px;
}
.smart-accordion-default.panel-group .panel + .panel {
  margin-top: -1px;
}
.smart-accordion-default.panel-group .panel-heading {
  padding: 0px;
}
.smart-accordion-default.panel-group .panel-title a {
  display: block;
  padding: 10px 15px;
  text-decoration: none !important;
}
.smart-accordion-default .panel-heading,
.panel-group .panel {
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
}
.smart-accordion-default .panel-default > .panel-heading {
  background-color: #fcfcfc;
}
.smart-accordion-default .panel-default {
  border-color: #c3c3c3;
}
.smart-accordion-default .panel-title > a > :first-child {
  display: none;
}
.smart-accordion-default .panel-title > a.collapsed > .fa {
  display: none;
}
.smart-accordion-default .panel-title > a.collapsed > :first-child {
  display: inline-block;
}
.no-padding .smart-accordion-default > div {
  border-left: none !important;
  border-right: none !important;
}
.no-padding .smart-accordion-default > div:first-child {
  border-top: none !important;
}
.no-padding .smart-accordion-default > div:last-child {
  border-bottom: none !important;
}
/*
 * ON OFF SWITCHES
 */
/* http://proto.io/freebies/onoff/ */
.onoffswitch-container {
  margin-top: 4px;
  margin-left: 7px;
  display: inline-block;
}
.onoffswitch {
  position: relative;
  width: 50px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle;
}
.onoffswitch-checkbox {
  display: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #626262;
  border-radius: 50px;
  border-color: #adadad #b3b3b3 #9e9e9e;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  display: block;
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 15px;
  padding: 0;
  line-height: 15px;
  font-size: 10px;
  color: #ffffff;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: attr(data-swchon-text);
  text-shadow: 0 -1px 0 #333333;
  padding-left: 7px;
  background-color: #3276b1;
  color: #ffffff;
  box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.5), 0 1px 2px rgba(0, 0, 0, 0.05);
  text-align: left;
}
.onoffswitch-inner:after {
  content: attr(data-swchoff-text);
  padding-right: 7px;
  text-shadow: 0 -1px 0 #ffffff;
  background-color: #ffffff;
  color: #555555;
  text-align: right;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.onoffswitch-switch {
  width: 19px;
  height: 19px;
  margin: -2px;
  background: white;
  border: 1px solid #9a9a9a;
  border-radius: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 32px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  background-color: #f4f4f4;
  background-image: -moz-linear-gradient(top, #ffffff, #eeeeee);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#eeeeee));
  background-image: -webkit-linear-gradient(top, #ffffff, #eeeeee);
  background-image: -o-linear-gradient(top, #ffffff, #eeeeee);
  background-image: linear-gradient(to bottom, #ffffff, #eeeeee);
  background-repeat: repeat-x;
  -webkit-box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.3);
}
.onoffswitch-checkbox + .onoffswitch-label .onoffswitch-switch:before,
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch:before {
  content: "\f00d";
  color: #a90329;
  display: block;
  text-align: center;
  line-height: 19px;
  font-size: 10px;
  text-shadow: 0 -1px 0 #ffffff;
  font-weight: bold;
  font-family: FontAwesome;
}
/* checked */
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch:before {
  content: "\f00c";
  color: #57889c;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
  display: block;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
.onoffswitch-switch:hover {
  background-color: #eeeeee;
}
.onoffswitch-switch:active {
  background-color: #eeeeee;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}
/*
 * Disabled state
 */
.onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-inner:after,
.onoffswitch-checkbox:checked:disabled + .onoffswitch-label .onoffswitch-inner:before {
  text-shadow: 0 1px 0 #ffffff;
  background: #bfbfbf;
  color: #333333;
}
.onoffswitch-checkbox:checked:disabled + .onoffswitch-label .onoffswitch-switch,
.onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-switch {
  background-color: #f4f4f4;
  background-image: -moz-linear-gradient(top, #bfbfbf, #f4f4f4);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#bfbfbf), to(#f4f4f4));
  background-image: -webkit-linear-gradient(top, #bfbfbf, #f4f4f4);
  background-image: -o-linear-gradient(top, #bfbfbf, #f4f4f4);
  background-image: linear-gradient(to bottom, #bfbfbf, #f4f4f4);
  box-shadow: none !important;
}
.onoffswitch-checkbox:disabled + .onoffswitch-label,
.onoffswitch-checkbox:checked:disabled + .onoffswitch-label .onoffswitch-label {
  border-color: #ababab #999999 #878787 !important;
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
  border-color: #3276b1 #2a6395 #255681;
}
.onoffswitch + span,
.onoffswitch-title {
  display: inline-block;
  vertical-align: middle;
  margin-top: -5px;
}
/*
 * FORMS
 */
.form-control {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}
.form hr {
  margin-left: -13px;
  margin-right: -13px;
  border-color: rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  margin-bottom: 20px;
}
.form fieldset {
  display: block;
  border: none;
  background: rgba(255, 255, 255, 0.9);
  position: relative;
}
fieldset {
  position: relative;
}
.form-actions {
  display: block;
  padding: 13px 14px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(249, 249, 249, 0.9);
  margin-top: 25px;
  margin-left: -13px;
  margin-right: -13px;
  margin-bottom: -13px;
  text-align: right;
}
.well .form-actions {
  margin-left: -19px;
  margin-right: -19px;
  margin-bottom: -19px;
}
.well.well-lg .form-actions {
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
}
.well.well-sm .form-actions {
  margin-left: -9px;
  margin-right: -9px;
  margin-bottom: -9px;
}
.popover-content .form-actions {
  margin: 0 -14px -9px;
  border-radius: 0 0 3px 3px;
  padding: 9px 14px;
}
.no-padding .form .form-actions {
  margin: 0;
  display: block;
  padding: 13px 14px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(248, 248, 248, 0.9);
  text-align: right;
  margin-top: 25px;
}
.form header,
legend,
.bootstrap-duallistbox-container label {
  display: block;
  padding: 8px 0;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  background: #ffffff;
  font-size: 16px;
  font-weight: 300;
  color: #222222;
  margin: 25px 0px 20px;
}
.no-padding .form header {
  margin: 25px 14px 0;
}
.form header:first-child {
  margin-top: 10px;
}
legend {
  font-weight: 400;
  margin-top: 0px;
  background: none;
}
.input-group-addon {
  padding: 6px 10px;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}
.input-group-addon {
  transition: border-color 0.3s;
  -o-transition: border-color 0.3s;
  -ms-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s;
  transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
}
.input-group-addon .fa {
  font-size: 14px;
}
.input-group-addon .fa-lg,
.input-group-addon .fa-2x {
  font-size: 2em;
}
.input-group-addon .fa-3x,
.input-group-addon .fa-4x,
.input-group-addon .fa-5x {
  font-size: 30px;
}
input[type="text"]:focus + .input-group-addon {
  border-color: #0091d9;
  background-color: #72a0ce;
  color: #ffffff;
}
.has-warning input[type="text"],
.has-warning input[type="text"] + .input-group-addon {
  border-color: #dbab57;
}
.has-warning input[type="text"] + .input-group-addon {
  background-color: #faf2cc;
  color: #c09853;
}
.has-warning input[type="text"]:focus,
.has-warning input[type="text"]:focus + .input-group-addon {
  border-color: #dbab57;
}
.has-warning input[type="text"]:focus + .input-group-addon {
  background-color: #e1c118;
  color: #ffffff;
}
.has-error .input-group-addon {
  border-color: #b94a48;
  background: #f2dede;
  color: #b94a48;
}
.has-error input[type="text"],
.has-error input[type="text"] + .input-group-addon {
  border-color: #b94a48;
}
.has-error input[type="text"] + .input-group-addon {
  background-color: #f2dede;
  color: #b94a48;
}
.has-error input[type="text"]:focus,
.has-error input[type="text"]:focus + .input-group-addon {
  border-color: #c05e5e;
}
.has-error input[type="text"]:focus + .input-group-addon {
  background-color: #c05e5e;
  color: #ffffff;
}
.has-success input[type="text"]:focus + .input-group-addon {
  border-color: #468847;
  background-color: #468847;
  color: #ffffff;
}
.form fieldset .form-group:last-child,
.form fieldset .form-group:last-child .note,
.form .form-group:last-child,
.form .form-group:last-child .note {
  margin-bottom: 0;
}
.note {
  margin-top: 6px;
  padding: 0 1px;
  font-size: 11px;
  line-height: 15px;
  color: #999999;
}
/*
 * WITH ICON
 */
.input-icon-right {
  position: relative;
}
.input-icon-right > i,
.input-icon-left > i {
  position: absolute;
  right: 10px;
  top: 30%;
  font-size: 16px;
  color: #bfbfbf;
}
.input-icon-left > i {
  right: auto;
  left: 24px;
}
.input-icon-right .form-control {
  padding-right: 27px;
}
.input-icon-left .form-control {
  padding-left: 29px;
}
.input-group-addon .checkbox,
.input-group-addon .radio {
  min-height: 0px;
  margin-right: 0px !important;
  padding-top: 0;
}
.input-group-addon label input[type="checkbox"].checkbox + span,
.input-group-addon label input[type="radio"].radiobox + span,
.input-group-addon label input[type="radio"].radiobox + span:before,
.input-group-addon label input[type="checkbox"].checkbox + span:before {
  margin-right: 0px;
}
.input-group-addon .onoffswitch,
.input-group-addon .onoffswitch-label {
  margin: 0;
}
/*
 * Timeline Seperator
 */
.timeline-seperator {
  display: block;
  text-align: center;
  color: #999999;
  margin: 20px 0 0;
}
.timeline-seperator > :first-child {
  padding: 4px 5px;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  background: #ffffff;
  font-size: 11px;
  border-radius: 4px;
}
.timeline-seperator .btn,
.timeline-seperator .btn + .dropdown-menu {
  margin-right: 15px;
}
.timeline-seperator:after {
  display: block;
  content: " ";
  margin: 0 20px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  margin-top: -8px;
}
/*
 * Alerts
 */
.alert {
  margin-bottom: 20px;
  margin-top: 0px;
  padding: 5px 15px 5px 34px;
  color: #675100;
  border-width: 0px;
  border-left-width: 5px;
  padding: 10px;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
}
.alert .close {
  top: 0px;
  right: -5px;
  line-height: 20px;
}
.alert-heading {
  font-weight: 600;
}
.alert-danger {
  border-color: #953b39;
  color: #ffffff;
  background-color: #c26565;
  text-shadow: none;
}
.alert-warning {
  border-color: #dfb56c;
  color: #826430;
  background-color: #efe1b3;
}
.alert-success {
  border-color: #8ac38b;
  color: #356635;
  background-color: #cde0c4;
}
.alert-info {
  border-color: #9cb4c5;
  color: #305d8c;
  background-color: #d6dde7;
}
/*
 * WELLS
 */
.well {
  background-color: #fbfbfb;
  border: 1px solid #dddddd;
  box-shadow: 0 1px 1px #ececec;
  -webkit-box-shadow: 0 1px 1px #ececec;
  -moz-box-shadow: 0 1px 1px #ececec;
  position: relative;
}
.well.well-clean {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: transparent;
}
.well.well-glass {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: transparent;
  background-color: rgba(255, 255, 255, 0.25);
  color: #ffffff;
}
.well.well-light {
  background: #ffffff;
  border: 1px solid #e2e2e2;
}
.widget-body .well {
  margin-bottom: 0px;
}
.well.transparent {
  border: none !important;
  background: none !important;
}
/* any wells with custom bg the border will be darkened */
.well[class^="bg-"],
.well[class*=" bg-"] {
  border: 1px solid #555555 !important;
}
/*
 * TABS
 */
.nav-tabs > li > a .badge {
  font-size: 11px;
  padding: 3px 5px 3px 5px;
  opacity: .5;
  margin-left: 5px;
  min-width: 17px;
  font-weight: normal;
}
.nav-tabs > li > a > .fa {
  opacity: .5;
}
.tabs-left .nav-tabs > li > a .badge {
  margin-right: 5px;
  margin-left: 0px;
}
.nav-tabs > li > a .label {
  display: inline-block;
  font-size: 11px;
  margin-left: 5px;
  opacity: .5;
}
.nav-tabs > li.active > a .badge,
.nav-tabs > li.active > a .label,
.nav-tabs > li.active > a > .fa {
  opacity: 1;
}
.nav-tabs > li > a {
  border-radius: 0px;
  color: #333333;
}
.nav-tabs > li.active > a {
  -webkit-box-shadow: 0 -2px 0 #57889c;
  -moz-box-shadow: 0 -2px 0 #57889c;
  box-shadow: 0 -2px 0 #57889c;
  border-top-width: 0px !important;
  margin-top: 1px !important;
  font-weight: bold;
}
.tabs-left .nav-tabs > li.active > a {
  -webkit-box-shadow: -2px 0 0 #57889c;
  -moz-box-shadow: -2px 0 0 #57889c;
  box-shadow: -2px 0 0 #57889c;
  border-top-width: 1px !important;
  border-left: none !important;
  margin-left: 1px !important;
}
.tabs-left .nav-pills > li.active > a {
  border: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
}
.tabs-right .nav-tabs > li.active > a {
  -webkit-box-shadow: 2px 0 0 #57889c;
  -moz-box-shadow: 2px 0 0 #57889c;
  box-shadow: 2px 0 0 #57889c;
  border-top-width: 1px !important;
  border-right: none !important;
  margin-right: 1px !important;
}
.tabs-below .nav-tabs > li.active > a {
  -webkit-box-shadow: 0 2px 0 #57889c;
  -moz-box-shadow: 0 2px 0 #57889c;
  box-shadow: 0 2px 0 #57889c;
  border-bottom-width: 0px !important;
  border-top: none !important;
  margin-top: 0px !important;
}
.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}
.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}
.tab-content > .active,
.pill-content > .active {
  display: block;
}
.tabs-below > .nav-tabs {
  border-top: 1px solid #dddddd;
}
.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}
.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li,
.tabs-left > .nav-pills > li,
.tabs-right > .nav-pills > li {
  float: none;
}
.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a,
.tabs-left > .nav-pills > li > a,
.tabs-right > .nav-pills > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}
.tabs-left > .nav-tabs,
.tabs-left > .nav-pills {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #dddddd;
}
.tabs-left > .nav-pills {
  border-right: none;
}
.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
}
.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
  border-color: #eeeeee #d5d5d5 #eeeeee #eeeeee;
}

.tabs-left > .tab-content {
  margin-left: 109px;
}
.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #dddddd;
  /* DDD */
}
.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
}
.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}
.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
  border-color: #dddddd #dddddd #dddddd transparent;
}
.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}
.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}
.tab-content > .active,
.pill-content > .active {
  display: block;
}
.tabs-below > .nav-tabs {
  border-top: 1px solid #dddddd;
}
.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0;
}
.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
  border-top-color: #dddddd;
  border-bottom-color: transparent;
}
.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover,
.tabs-below > .nav-tabs > .active > a:focus {
  border-color: transparent #dddddd #dddddd #dddddd;
  /* tabs */
}
/* bordered tabs */
.nav-tabs.bordered {
  background: #ffffff;
  border: 1px solid #dddddd;
}
.nav-tabs.bordered > :first-child a {
  border-left-width: 0px !important;
}
.nav-tabs.bordered + .tab-content {
  border: 1px solid #dddddd;
  border-top: none;
}
/* bordered tabs top right */
.tabs-pull-right.nav-tabs > li,
.tabs-pull-right.nav-pills > li {
  float: right;
}
.tabs-pull-right.nav-tabs > li:first-child > a,
.tabs-pull-right.nav-pills > li:first-child > a {
  margin-right: 1px;
}
.tabs-pull-right.bordered.nav-tabs > li:first-child > a,
.tabs-pull-right.bordered.nav-pills > li:first-child > a {
  border-left-width: 1px !important;
  margin-right: 0px;
  border-right-width: 0px;
}
/*
 * WEBKIT IOS HIGHLIGHT COLOR
 *
 */
a:link,
.btn,
button {
  -webkit-tap-highlight-color: rgba(169, 3, 41, 0.5);
}
.login-info,
.login-info *,
#logo-group *,
.minified .menu-item-parent {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
/*
 * BS2 useful classes
 * these classes and its importance was overlooked in BS3
 */
.hidden-desktop {
  display: none !important;
}
/*
 * DROPDOWN MENU
 */
.dropdown-menu-xs {
  min-width: 37px;
}
.dropdown-menu-xs > li > a {
  padding: 3px 10px;
}
.dropdown-menu-xs > li > a:hover i {
  color: #fff !important;
}
/* Dropdown menu extended */
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #bfbfbf;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #ffffff;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}
/*
 * Table
 */
.table.has-tickbox thead tr th:first-child {
  width: 18px;
}
.table.has-tickbox.smart-form thead tr th:first-child .radio,
.table.has-tickbox.smart-form thead tr th:first-child .checkbox,
.table.has-tickbox.smart-form tbody tr td:first-child .radio,
.table.has-tickbox.smart-form tbody tr td:first-child .checkbox {
  padding: 0px !important;
}
.table thead tr,
.fc-head-container thead tr {
  background-color: #eeeeee;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f2f2f2), to(#fafafa));
  background-image: -webkit-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -moz-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -ms-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -o-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  background-image: -linear-gradient(top, #f2f2f2 0, #fafafa 100%);
  font-size: 12px;
}
.fc-head-container thead tr th {
  padding: 4px;
  line-height: 1.428571429;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-width: 1px;
}
.no-padding > .table-bordered,
.no-padding > .table-responsive > .table-bordered {
  border: none !important;
}
.no-padding > .table-bordered thead tr th:first-child,
.no-padding > .table-bordered thead tr td:first-child,
.no-padding > .table-bordered tbody tr td:first-child,
.no-padding > .table-responsive > .table-bordered thead tr th:first-child,
.no-padding > .table-responsive > .table-bordered thead tr td:first-child,
.no-padding > .table-responsive > .table-bordered tbody tr td:first-child {
  border-left-width: 0px !important;
}
.no-padding > .table-bordered thead tr th:last-child,
.no-padding > .table-bordered tbody tr td:last-child,
.no-padding > .table-responsive > .table-bordered thead tr th:last-child,
.no-padding > .table-responsive > .table-bordered tbody tr td:last-child {
  border-right-width: 0px !important;
}
.no-padding > .table-bordered tbody tr:last-child td,
.no-padding > .table-responsive > .table-bordered tbody tr:last-child td {
  border-bottom-width: 0px !important;
}
.no-padding .table-responsive .table {
  margin-bottom: 0;
}
.no-padding .note-editable .table-bordered {
  border: 1px solid #dddddd !important;
}
/*
 * Client page forms
 */
.client-form header {
  padding: 15px 13px;
  margin: 0;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  background: rgba(248, 248, 248, 0.9);
}
/*
 * ALTERNATIVE PAGING
 */
.pagination.pagination-alt > li > a {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
  margin-left: -1px;
}
.pagination.pagination-alt > li:first-child > a {
  padding-left: 0px;
}
/*
 * CUSTOM BUTTON
 */
.pagination > li > a,
.pagination > li > span {
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
}
.disabled {
  color: #ffffff;
}
.btn-default.disabled {
  color: #999999;
}
.btn {
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.05);
}
.btn:active {
  position: relative;
  top: 1px;
  left: 1px;
}
.btn.btn-ribbon {
  background-color: #707070;
  background-image: -moz-linear-gradient(top, #777777, #666666);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#777777), to(#666666));
  background-image: -webkit-linear-gradient(top, #777777, #666666);
  background-image: -o-linear-gradient(top, #777777, #666666);
  background-image: linear-gradient(to bottom, #777777, #666666);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff777777', endColorstr='#ff666666', GradientType=0);
  color: white;
  padding: 0 5px;
  line-height: 20px;
  vertical-align: middle;
  height: 20px;
  display: block;
  border: none;
  float: left;
  margin: 0 8px 0 0;
  cursor: pointer;
}
.btn.btn-ribbon > i {
  font-size: 111%;
}
.ribbon-button-alignment {
  padding-top: 10px;
  display: inline-block;
}
.ribbon-button-alignment.pull-right > .btn.btn-ribbon {
  margin: 0 0 0 8px;
}
/*
 * Panels
 */
.panel-purple {
  border-color: #6e587a;
}
.panel-purple > .panel-heading {
  color: #ffffff;
  background-color: #6e587a;
  border-color: #6e587a;
}
.panel-greenLight {
  border-color: #71843f;
}
.panel-greenLight > .panel-heading {
  color: #ffffff;
  background-color: #71843f;
  border-color: #71843f;
}
.panel-greenDark {
  border-color: #496949;
}
.panel-greenDark > .panel-heading {
  color: #ffffff;
  background-color: #496949;
  border-color: #496949;
}
.panel-darken {
  border-color: #333333;
}
.panel-darken > .panel-heading {
  color: #ffffff;
  background-color: #404040;
  border-color: #404040;
}
.panel-pink {
  border-color: #ac5287;
}
.panel-pink > .panel-heading {
  color: #ffffff;
  background-color: #ac5287;
  border-color: #ac5287;
}
.panel-green {
  border-color: #356e35;
}
.panel-green > .panel-heading {
  color: #ffffff;
  background-color: #356e35;
  border-color: #356e35;
}
.panel-blueLight {
  border-color: #92a2a8;
}
.panel-blueLight > .panel-heading {
  color: #ffffff;
  background-color: #92a2a8;
  border-color: #92a2a8;
}
.panel-pinkDark {
  border-color: #a8829f;
}
.panel-pinkDark > .panel-heading {
  color: #ffffff;
  background-color: #a8829f;
  border-color: #a8829f;
}
.panel-redLight {
  border-color: #a65858;
}
.panel-redLight > .panel-heading {
  color: #ffffff;
  background-color: #a65858;
  border-color: #a65858;
}
.panel-red {
  border-color: #a90329;
}
.panel-red > .panel-heading {
  color: #ffffff;
  background-color: #a90329;
  border-color: #a90329;
}
.panel-teal {
  border-color: #568a89;
}
.panel-teal > .panel-heading {
  color: #ffffff;
  background-color: #568a89;
  border-color: #568a89;
}
.panel-orange {
  border-color: #c79121;
}
.panel-orange > .panel-heading {
  color: #ffffff;
  background-color: #c79121;
  border-color: #c79121;
}
.panel-blueDark {
  border-color: #4c4f53;
}
.panel-blueDark > .panel-heading {
  color: #ffffff;
  background-color: #4c4f53;
  border-color: #4c4f53;
}
.panel-magenta {
  border-color: #6e3671;
}
.panel-magenta > .panel-heading {
  color: #ffffff;
  background-color: #6e3671;
  border-color: #6e3671;
}
.panel-blue {
  border-color: #57889c;
}
.panel-blue > .panel-heading {
  color: #ffffff;
  background-color: #57889c;
  border-color: #57889c;
}
.panel-footer > .btn-block {
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-bottom: none;
  border-left: none;
  border-right: none;
}
/*
 * Circle Buttons
 */
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 18px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 15px;
  font-size: 18px;
  line-height: 30px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 15px;
  font-size: 24px;
  line-height: 50px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
/*
 * Big Metro Buttons
 */
.btn-metro {
  margin: 0 0 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.btn-metro > span {
  display: block;
  vertical-align: bottom;
  margin-top: 10px;
  text-transform: uppercase;
}
.btn-metro > span.label {
  position: absolute;
  top: 0px;
  right: 0px;
}
/*
 * buttons with labels
 */
.btn-label {
  position: relative;
  left: -12px;
  display: inline-block;
  padding: 6px 12px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 3px 0 0 3px;
}
.btn-labeled {
  padding-top: 0;
  padding-bottom: 0;
}
.btn-link {
  box-shadow: none;
  -webkit-box-shadow: none;
  font-size: 14px;
}
/*
 * ANIMATION
 */
#left-panel {
  -webkit-transition: 250ms cubic-bezier(0.1, 0.57, 0.1, 1);
  transition: 250ms cubic-bezier(0.1, 0.57, 0.1, 1);
  -webkit-transform: translate(0px, 0px) translateZ(0px);
}
/* removed as it was too jerky */
/*#main {
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
}*/
/*
 * CHARTS
 */
.chart-small,
.chart-large,
.chart-xl {
  overflow: hidden;
}
.chart-small {
  height: 100px;
  width: 100%;
}
.chart-large {
  height: 235px;
  width: 100%;
}
.chart-xl {
  height: 297px;
  width: 100%;
}
/*
 * Flot Tool tips
 */
#flotTip {
  padding: 3px 5px;
  background-color: #ffffff;
  z-index: 9999;
  color: #333333;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  border: 1px solid #C1C1C1;
  -moz-border-radius: 4px;
  -khtml-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
#flotTip span {
  color: #38812D;
  font-weight: bold;
}
.legendLabel span {
  display: block;
  margin: 0 5px;
}
.legendColorBox {
  padding-left: 10px;
  vertical-align: top;
  padding-top: 5px;
}
.legendColorBox div > div {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}
/*
 * Morris Charts
 */
.morris-hover.morris-default-style {
  border-radius: 10px;
  padding: 6px;
  color: #666;
  background: rgba(255, 255, 255, 0.8);
  border: solid 2px rgba(230, 230, 230, 0.8);
  font-family: sans-serif;
  font-size: 12px;
  text-align: center;
}
.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: bold;
  margin: 0.25em 0;
}
.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
}
.morris-hover {
  position: absolute;
  z-index: 903;
}
.fixed-page-footer .morris-hover {
  z-index: 900;
}
/*
 * Flot Chart
 */
.chart {
  overflow: hidden;
  /*- disabled for widget chrome bug */
  height: 220px;
  margin: 20px 5px 10px 10px;
}
.has-legend {
  margin-top: 30px !important;
}
.has-legend-unique {
  margin-top: 19px !important;
}
/*
 * Carren colors
 */
.icon-color-good {
  color: #40ac2b;
}
.icon-color-bad {
  color: #ed1c24;
}
/*
 * SIGNS
 */
.percent-sign:after,
.degree-sign:after {
  content: " %";
  line-height: normal;
}
.degree-sign:after {
  content: "°F";
}
/*
 * HIDDEN MENU
 */
.hidden-menu #left-panel {
  left: -210px;
  z-index: 903;
}
.hidden-menu #main {
  margin-left: 10px;
}
.hidden-menu #left-panel:hover {
  left: 0px;
}
.hidden-menu .minifyme {
  display: none;
}
.minified #hide-menu {
  display: none;
}
.btn-header.pull-right {
  margin-left: 6px;
}
.btn-header a > span {
  font-size: 13px;
  font-weight: normal;
  line-height: 30px;
  height: 30px;
  display: inline-block;
}
.btn-header > :first-child > a {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  cursor: default !important;
  display: inline-block;
  font-weight: bold;
  height: 30px;
  line-height: 24px;
  min-width: 30px;
  padding: 2px;
  text-align: center;
  text-decoration: none !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  background-color: #f8f8f8;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f8f8f8), to(#f1f1f1));
  background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -moz-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -ms-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: -o-linear-gradient(top, #f8f8f8, #f1f1f1);
  background-image: linear-gradient(top, #f8f8f8, #f1f1f1);
  border: 1px solid #bfbfbf;
  color: #6D6A69;
  font-size: 17px;
  margin: 10px 0 0 0;
}
.btn-header > :first-child > a:hover {
  border: 1px solid #bfbfbf;
  color: #222;
  transition: all 0.0s;
  cursor: pointer;
  -webkit-box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
}
.btn-header > :first-child > a:active {
  background-color: #e8e8e8;
  background-image: -moz-linear-gradient(top, #e8e8e8 0%, #ededed 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e8e8e8), color-stop(100%, #ededed));
  background-image: -webkit-linear-gradient(top, #e8e8e8 0%, #ededed 100%);
  background-image: -o-linear-gradient(top, #e8e8e8 0%, #ededed 100%);
  background-image: -ms-linear-gradient(top, #e8e8e8 0%, #ededed 100%);
  background-image: linear-gradient(to bottom, #e8e8e8 0%, #ededed 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e8e8e8', endColorstr='#ededed', GradientType=0);
  -webkit-box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
}
.hidden-menu #hide-menu > :first-child > a {
  background-color: #e8e8e8;
  background-image: -moz-linear-gradient(top, #5a5a5a 0%, #686868 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #5a5a5a), color-stop(100%, #686868));
  background-image: -webkit-linear-gradient(to bottom, #5a5a5a 0%, #686868 100%);
  background-image: -o-linear-gradient(to bottom, #5a5a5a 0%, #686868 100%);
  background-image: -ms-linear-gradient(to bottom, #5a5a5a 0%, #686868 100%);
  background-image: linear-gradient(to bottom, #5a5a5a 0%, #686868 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5A5A5A', endColorstr='#686868', GradientType=0);
  -webkit-box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  border-color: #494949;
}
.hidden-menu #hide-menu > :first-child > a:active,
.full-screen #fullscreen > :first-child > a:active {
  -webkit-box-shadow: inset 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
}
/*
 * LARGE DROPDOWNS
 */
.dropdown-large {
  position: static !important;
}
.dropdown-menu-large {
  margin-left: 16px;
  margin-right: 16px;
  padding: 20px 0px;
}
.dropdown-menu-large > li > ul {
  padding: 0;
  margin: 0;
}
.dropdown-menu-large > li > ul > li {
  list-style: none;
}
.dropdown-menu-large > li > ul > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #333333;
  white-space: normal;
}
.dropdown-menu-large > li ul > li > a:hover,
.dropdown-menu-large > li ul > li > a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}
.dropdown-menu-large .disabled > a,
.dropdown-menu-large .disabled > a:hover,
.dropdown-menu-large .disabled > a:focus {
  color: #999999;
}
.dropdown-menu-large .disabled > a:hover,
.dropdown-menu-large .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  cursor: not-allowed;
}
.dropdown-menu-large .dropdown-header {
  color: #428bca;
  font-size: 18px;
}
@media (max-width: 768px) {
  .dropdown-menu-large {
    margin-left: 0;
    margin-right: 0;
  }
  .dropdown-menu-large > li {
    margin-bottom: 30px;
  }
  .dropdown-menu-large > li:last-child {
    margin-bottom: 0;
  }
  .dropdown-menu-large .dropdown-header {
    padding: 3px 15px !important;
  }
}
/*
 * PAGING
 */
.pagination-xs > li > a,
.pagination-xs > li > span {
  padding: 4px 9px;
  font-size: 12px;
}
/*
 * BOOTSTRAP TOOLTIP
 */
.tooltip-inner {
  padding: 5px 10px;
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  border-radius: 0px;
}
/*
 * FORMS: INPUT WITH ICONS
 */
.input-group .icon-addon .form-control {
  border-radius: 0;
}
.icon-addon {
  position: relative;
  color: #555;
  display: block;
}
.icon-addon:after,
.icon-addon:before {
  display: table;
  content: " ";
}
.icon-addon:after {
  clear: both;
}
.icon-addon.addon-md .glyphicon,
.icon-addon .glyphicon,
.icon-addon.addon-md .fa,
.icon-addon .fa {
  position: absolute;
  z-index: 2;
  left: 10px;
  width: 20px;
  margin-left: -2.5px;
  text-align: center;
  padding: 10px 0;
  top: 1px;
}
.icon-addon.addon-lg .form-control {
  line-height: 1.33;
  height: 46px;
  font-size: 18px;
  padding: 10px 16px 10px 40px;
}
.input-group-lg .icon-addon.addon-lg + .input-group-btn > .btn {
  height: 46px;
}
.icon-addon.addon-sm .form-control {
  height: 30px;
  padding: 5px 10px 5px 28px;
  font-size: 12px;
  line-height: 1.5;
}
.icon-addon.addon-lg .fa,
.icon-addon.addon-lg .glyphicon {
  font-size: 18px;
  margin-left: 0;
  left: 11px;
  top: 4px;
}
.icon-addon.addon-md .form-control,
.icon-addon .form-control {
  padding-left: 30px;
  float: left;
  font-weight: normal;
}
.icon-addon.addon-sm .fa,
.icon-addon.addon-sm .glyphicon {
  margin-left: 0;
  font-size: 12px;
  left: 5px;
  top: -1px;
}
.icon-addon .form-control:focus + .glyphicon,
.icon-addon:hover .glyphicon,
.icon-addon .form-control:focus + .fa,
.icon-addon:hover .fa {
  color: #2580db;
}
.icon-addon input:disabled + .glyphicon,
.icon-addon select:disabled + .glyphicon,
.icon-addon input:disabled + .fa,
.icon-addon select:disabled + .fa {
  color: #ccc;
}
.progress {
  position: relative;
}
.progress .progress-bar {
  position: absolute;
  overflow: hidden;
  line-height: 18px;
}
.progress .progressbar-back-text {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
.progress .progressbar-front-text {
  display: block;
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
.progress.right .progress-bar {
  right: 0;
}
.progress.right .progressbar-front-text {
  position: absolute;
  right: 0;
}
.progress.vertical {
  width: 18px;
  height: 100%;
  float: left;
  margin-right: 18px;
}
.progress.vertical.bottom {
  position: relative;
}
.progress.vertical.bottom .progressbar-front-text {
  position: absolute;
  bottom: 0;
}
.progress.vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
  -webkit-transition: all height 0.6s ease ease-out;
  -moz-transition: all height 0.6s ease ease-out;
  -o-transition: all height 0.6s ease ease-out;
  transition: all height 0.6s ease ease-out;
}
.progress.vertical.bottom .progress-bar {
  position: absolute;
  bottom: 0;
}
/*
 * PROGRESS BARS
 */
.progress-micro {
  height: 3px !important;
  line-height: 3px !important;
}
.progress-xs {
  height: 7px !important;
  line-height: 7px !important;
}
.progress-sm {
  height: 14px !important;
  line-height: 14px !important;
}
.progress-lg {
  height: 30px !important;
  line-height: 30px !important;
}
.progress .progress-bar {
  position: absolute;
  overflow: hidden;
  line-height: 20px;
}
.progress .progressbar-back-text {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}
.progress .progressbar-front-text {
  display: block;
  width: 100%;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}
.progress.right .progress-bar {
  right: 0;
}
.progress.right .progressbar-front-text {
  position: absolute;
  right: 0;
}
.progress.vertical {
  width: 25px;
  height: 100%;
  min-height: 150px;
  margin-right: 20px;
  display: inline-block;
  margin-bottom: 0px;
}
.progress.wide-bar {
  width: 40px;
}
.progress.vertical.bottom {
  position: relative;
}
.progress.vertical.bottom .progressbar-front-text {
  position: absolute;
  bottom: 0;
}
.progress.vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  transition: height 0.6s ease;
}
.progress.vertical.bottom .progress-bar {
  position: absolute;
  bottom: 0;
}
.progress {
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
  height: 22px;
  background: #eeeeee;
  box-shadow: 0 1px 0 transparent, 0 0 0 1px #e3e3e3 inset;
  -webkit-box-shadow: 0 1px 0 transparent, 0 0 0 1px #e3e3e3 inset;
  -moz-box-shadow: 0 1px 0 transparent, 0 0 0 1px #e3e3e3 inset;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}
.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 11px;
  color: #ffffff;
  text-align: center;
  background-color: #57889c;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  font-weight: bold;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.progress-striped .progress-bar {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}
.progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -moz-animation: progress-bar-stripes 2s linear infinite;
  -ms-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}
.progress-bar-danger {
  background-color: #a90329;
}
.progress-striped .progress-bar-danger {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-success {
  background-color: #739e73;
}
.progress-striped .progress-bar-success {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-warning {
  background-color: #c79121;
}
.progress-striped .progress-bar-warning {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-info {
  background-color: #57889c;
}
.progress-striped .progress-bar-info {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-info .bar,
.progress .bar-info {
  background: #57889c;
}
.vertical-bars {
  padding: 0;
  margin: 0;
}
.vertical-bars:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
}
.vertical-bars li {
  padding: 14px 0;
  width: 25%;
  display: block;
  float: left;
  text-align: center;
}
.vertical-bars li:first-child {
  border-left: none;
}
.vertical-bars > li > .progress.vertical:first-child {
  margin-left: auto;
}
.vertical-bars > li > .progress.vertical {
  margin: 0 auto;
  float: none;
}
/*
 * BAR HOLDER
 */
.bar-holder:first-child {
  border-top: none;
}
.bar-holder {
  padding: 18px 15px;
}
.bar-holder .progress {
  margin: 0;
}
/* This issue relates to container + toggle menu with a result of overflow. */
html.hidden-menu-mobile-lock {
  overflow-x: hidden;
}
/*
 * No Padding
 */
.no-content-padding {
  margin: -10px -14px 0 -14px;
  /*used with content >:first-child */
}
.no-padding {
  padding: 0px !important;
}
.no-padding > pre {
  margin: 30px;
}
.no-padding  > table,
.no-padding .dataTables_wrapper table {
  border: none !important;
  margin-bottom: 0px !important;
  border-bottom-width: 0px !important;
}
.no-padding-bottom {
  padding-bottom: 0px !important;
}
.padding-5 {
  padding: 5px !important;
}
.padding-7 {
  padding: 7px !important;
}
.padding-10 {
  padding: 10px !important;
}
.padding-gutter {
  padding: 13px !important;
}
.padding-top-10 {
  padding-top: 10px !important;
}
.padding-top-15 {
  padding-top: 15px !important;
}
.padding-bottom-10 {
  padding-bottom: 10px !important;
}
.padding-bottom-0 {
  padding-bottom: 0px !important;
}
.padding-top-0 {
  padding-top: 0px !important;
}
.no-padding  > table tr td:last-child,
.no-padding  > table tr th:last-child,
.no-padding .dataTables_wrapper table tr td:last-child,
.no-padding .dataTables_wrapper table tr th:last-child {
  border-right: none !important;
}
.no-padding  > table tr td:first-child,
.no-padding  > table tr th:first-child,
.no-padding .dataTables_wrapper table tr td:first-child,
.no-padding .dataTables_wrapper table tr th:first-child {
  border-left: none !important;
}
.no-padding .dataTables_wrapper table tbody tr:last-child td {
  border-bottom: 0;
}
/*
 * Font Weights
 */
.semi-bold {
  font-weight: 400 !important;
}
.ultra-light {
  font-weight: 300 !important;
}
/*
 * Font Sizes
 */
.font-xs {
  font-size: 85% !important;
}
.font-sm {
  font-size: 95% !important;
}
.font-md {
  font-size: 130% !important;
}
.font-lg {
  font-size: 160% !important;
}
.font-xl {
  font-size: 200% !important;
}
.font-400 {
  font-size: 400% !important;
}
/*
 * Margins
 */
.no-margin {
  margin: 0px !important;
}
.margin-top-10 {
  margin-top: 10px !important;
}
.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-right-5 {
  margin-right: 5px !important;
}
.margin-bottom-0 {
  margin-bottom: 0px !important;
}
/*
 * Inlines
 */
.display-inline {
  display: inline-block !important;
}
.display-normal {
  display: inline !important;
}
/*
 * CURSORS
 */
.cursor-pointer {
  cursor: pointer;
}
/*
 * Forced text alignments
 */
.text-align-center,
.text-center {
  text-align: center !important;
}
.text-align-left,
.text-left {
  text-align: left !important;
}
.text-align-right,
.text-right {
  text-align: right !important;
}
/*
 * HR
 */
hr.simple {
  margin-top: 10px;
  margin-bottom: 10px;
  border-style: dashed;
}
/*
 * SHORT CUTS
 */
.pull-right {
  float: right !Important;
}
.pull-left {
  float: left !Important;
}
/*
 * Air fields
 * Description: absolute positioned objects
 */
.air {
  position: absolute;
  z-index: 5;
}
.air-bottom {
  bottom: 0px;
}
.air-top {
  top: 0px;
}
.air-top-right {
  top: 0px;
  right: 0px;
}
.air-top-left {
  top: 0px;
  left: 0px;
}
.air-bottom-left {
  bottom: 0px;
  left: 0px;
}
.air-bottom-right {
  bottom: 0px;
  right: 0px;
}
/*
 * Display none
 */
.display-none {
  display: none !important;
}
/*
 * Custom Radio and Input
 * SETUP
 */
.checkbox input[type="checkbox"].checkbox + span,
.checkbox-inline input[type="checkbox"].checkbox + span,
.radiobox-inline input[type="radio"].radiobox + span,
.radio input[type="radio"].radiobox + span {
  margin-left: -20px;
}
.form-inline .checkbox input[type="checkbox"].checkbox + span,
.form-inline .radiobox input[type="radio"].radiobox + span {
  margin-left: 0px;
}
.form-inline .checkbox,
.form-inline .radiobox {
  padding-left: 0px;
}
label input[type="radio"].radiobox + span,
input[type="checkbox"].checkbox + span {
  vertical-align: middle;
  line-height: 20px;
}
input[type="checkbox"].checkbox + span,
input[type="checkbox"].checkbox + span:after,
input[type="checkbox"].checkbox + span:before,
input[type="radio"].radiobox + span,
input[type="radio"].radiobox + span:after,
input[type="radio"].radiobox + span:before {
  box-sizing: content-box !important;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
}
input[type="checkbox"].checkbox,
input[type="radio"].radiobox {
  visibility: hidden;
  position: absolute;
  z-index: 20;
  width: 18px;
  height: 18px;
}
label input[type="checkbox"].checkbox + span,
label input[type="radio"].radiobox + span {
  position: relative;
  z-index: 19;
  display: inline-block;
  margin: 0;
  line-height: 17px;
  min-height: 14px;
  min-width: 14px;
  margin-right: 5px;
}
label input[type="checkbox"].checkbox + span:hover,
label input[type="radio"].radiobox + span:hover {
  cursor: pointer;
}
label input[type="radio"].radiobox + span:before,
label input[type="checkbox"].checkbox + span:before {
  font-family: 'FontAwesome';
  font-size: 12px;
  border-radius: 0;
  content: "\a0";
  /* we use '\a0'; to simulate a non-breaking-space (http://milov.nl/7029) */
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 1px;
  height: 12px;
  line-height: 12px;
  min-width: 12px;
  margin-right: 5px;
  border: 1px solid #bfbfbf;
  background-color: #f4f4f4;
  font-weight: normal;
  margin-top: -1px;
}
/*
 * RADIO
 */
label input[type="radio"].radiobox + span:before {
  content: "\a0";
  /* we use '\a0'; to simulate a non-breaking-space (http://milov.nl/7029) */
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
}
label input[type="radio"].radiobox:checked + span {
  font-weight: bold;
}
/* DEFAULT COLOR */
label input[type="radio"].radiobox:checked + span:before {
  content: "\f111";
  /* circle */
  color: #2E7BCC;
}
/* ORANGE COLOR */
label input[type="radio"].radiobox.style-3:checked + span:before {
  color: #a90329;
  border-color: #a90329;
}
/* CHROME COLOR */
label input[type="radio"].radiobox.style-2:checked + span:before {
  color: #66665e;
  border-color: #4d90fe;
  font-size: 9px;
}
label input[type="radio"].radiobox.style-2:checked + span {
  font-weight: normal;
}
/* Style 1 Radio */
label input[type="radio"].radiobox.style-1 + span:before {
  font-size: 9px;
  background: #ffffff;
  text-shadow: none;
  box-shadow: none !important;
  border-width: 1px;
  height: 13px;
  line-height: 13px;
  min-width: 13px;
}
label input[type="radio"].radiobox.style-1:checked + span:before {
  font-size: 10px;
  color: #333333;
  border-color: #333333;
  background: #ffffff;
}
label input[type="radio"].radiobox.style-1:checked + span {
  font-weight: normal;
}
/*
 * RADIO:HOVER & ACTIVE STATES
 */
/* DEFAULT:HOVER radio content --> 2022 */
label:hover input[type="radio"].radiobox + span:before,
label:hover input[type="radio"].radiobox:checked + span:before {
  color: #2E7BCC;
  border-color: #2E7BCC;
}
/* ORANGE:HOVER */
label:hover input[type="radio"].radiobox.style-3 + span:before,
label:hover input[type="radio"].radiobox.style-3:checked + span:before {
  color: #a90329;
  border-color: #a90329;
}
/* CHROME:HOVER */
label:hover input[type="radio"].radiobox.style-2:checked + span:before {
  color: #66665e;
  border-color: #4d90fe;
}
/* METRO:HOVER */
label:hover input[type="radio"].radiobox.style-1:checked + span:before {
  color: #333333;
  border-color: #333333;
  background: #ffffff;
}
label:hover input[type="radio"].radiobox.style-1 + span:before {
  color: #333333;
  border-color: #333333;
  background: #ffffff;
}
/* DEFAULT:ACTIVE */
label:active input[type="radio"].radiobox + span:before {
  box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, 0.3), 0px 1px 0px 0px rgba(255, 255, 255, 0.4);
}
/*
 * RADIO:DISABLE STATES
 */
/* GLOBAL */
label input[type="radio"][disabled].radiobox + span,
label input[type="radio"][disabled].radiobox:checked + span,
label:hover input[type="radio"][disabled].radiobox:checked + span:before {
  color: #A7A7A7;
  cursor: not-allowed;
}
label input[type="radio"][disabled].radiobox:checked + span:before {
  content: "\f111";
}
label input[type="radio"][disabled].radiobox:checked + span:before,
label input[type="radio"][disabled].radiobox + span:before,
label:active input[type="radio"][disabled].radiobox:checked + span:before,
label:hover input[type="radio"][disabled].radiobox:checked + span:before {
  background: #eee !important;
  border-color: #CCC !important;
  box-shadow: none;
  color: #A7A7A7;
}
/*
 * CHECKBOX
 */
label input[type="checkbox"].checkbox + span:before {
  content: "\a0";
  /* we use '\a0'; to simulate a non-breaking-space (http://milov.nl/7029) */
}
label input[type="checkbox"].checkbox:checked + span:before {
  content: "\f00c";
}
label input[type="checkbox"].checkbox:checked + span {
  font-weight: bold;
}
/* DEFAULT COLOR */
label input[type="checkbox"].checkbox:checked + span:before {
  color: #2E7BCC;
}
/* RED COLOR */
label input[type="checkbox"].checkbox.style-3:checked + span:before {
  color: #ffffff;
  border-color: #a90329;
  background: #a90329;
}
/* CHROME */
label input[type="checkbox"].checkbox.style-2 + span:before {
  background: transparent;
  border-color: #c1c1c1;
  box-shadow: none;
}
label input[type="checkbox"].checkbox.style-2:checked + span:before {
  content: "\f00c";
  color: #66665e;
  border-color: #4d90fe;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIFdpbmRvd3MiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MUQ0MEM4RUFEQUE4MTFFMkEwMjM5NzlCRjAxM0UwRjciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MUQ0MEM4RUJEQUE4MTFFMkEwMjM5NzlCRjAxM0UwRjciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxRDQwQzhFOERBQTgxMUUyQTAyMzk3OUJGMDEzRTBGNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxRDQwQzhFOURBQTgxMUUyQTAyMzk3OUJGMDEzRTBGNyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsD/PVAAAAAQSURBVHjaYv7//78vQIABAAlYA05NMPppAAAAAElFTkSuQmCC) #ffffff;
  /*#fefeec*/
}
label input[type="checkbox"].checkbox.style-2:checked + span {
  font-weight: normal;
}
/* METRO COLOR */
label input[type="checkbox"].checkbox.style-1 + span:before {
  color: #333333;
  background: #ffffff;
  text-shadow: none;
  box-shadow: none !important;
  border-width: 1px;
  height: 12px;
  line-height: 13px;
  min-width: 12px;
}
label input[type="checkbox"].checkbox.style-1:checked + span:before {
  color: #333333;
  border-color: #333333;
}
label input[type="checkbox"].checkbox.style-1:checked + span {
  font-weight: normal;
}
/*
 * CHECKBOX:HOVER & ACTIVE STATES
 */
label:active input[type="checkbox"].checkbox + span:before {
  box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, 0.3), 0px 1px 0px 0px rgba(255, 255, 255, 0.4);
}
/* DEFAULT:HOVER */
label:hover input[type="checkbox"].checkbox + span:before {
  color: #2E7BCC;
  border-color: #2E7BCC;
}
/* RED:HOVER*/
label:hover input[type="checkbox"].checkbox.style-3 + span:before {
  color: #ffffff;
  border-color: #A90327;
}
/* CHROME:HOVER*/
label:hover input[type="checkbox"].checkbox.style-2 + span:before {
  box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 0px 0px rgba(255, 255, 255, 0.2);
}
label:hover input[type="checkbox"].checkbox.style-2:checked + span:before {
  box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, 0.3), 0px 1px 0px 0px rgba(255, 255, 255, 0.4);
}
/* METRO:HOVER*/
label:hover input[type="checkbox"].checkbox.style-1 + span:before {
  border-color: #333333;
}
/* DEFAULT:ACTIVE */
label:active input[type="checkbox"].checkbox:checked + span:before {
  color: #ffffff;
  background: #2E7BCC;
  border-color: #2E7BCC;
}
/* RED:ACTIVE */
label:active input[type="checkbox"].checkbox.style-3:checked + span:before {
  color: #a90329;
  background: #ffffff;
  border-color: #a90329;
}
/* CHROME:ACTIVE */
label:active input[type="checkbox"].checkbox.style-2:checked + span:before {
  color: #66665e;
  background: transparent;
  border-color: #c1c1c1;
  box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, 0.3), 0px 1px 0px 0px rgba(255, 255, 255, 0.4);
}
label:active input[type="checkbox"].checkbox.style-2 + span:before {
  color: #66665e;
  background: transparent;
  border-color: #c1c1c1;
  box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, 0.3), 0px 1px 0px 0px rgba(255, 255, 255, 0.4);
}
/* METRO:ACTIVE */
label:active input[type="checkbox"].checkbox.style-1:checked + span:before {
  color: #ffffff;
  background: #333333;
  border-color: #333333;
}
/*
 * CHECKBOX:DISABLE STATES
 */
/* GLOBAL */
label input[type="checkbox"][disabled].checkbox + span,
label input[type="checkbox"][disabled].checkbox:checked + span,
label:hover input[type="checkbox"][disabled].checkbox:checked + span:before {
  color: #A7A7A7;
  cursor: not-allowed;
}
label input[type="checkbox"][disabled].checkbox:checked + span:before {
  content: "\f00c";
  color: #A7A7A7;
}
label input[type="checkbox"][disabled].checkbox.style-2:checked + span:before {
  content: "\f00c";
}
label input[type="checkbox"][disabled].checkbox:checked + span:before,
label input[type="checkbox"][disabled].checkbox + span:before,
label:active input[type="checkbox"][disabled].checkbox:checked + span:before,
label:hover input[type="checkbox"][disabled].checkbox:checked + span:before {
  background: #eee !important;
  border-color: #CCC !important;
  box-shadow: none;
  color: #A7A7A7;
  box-shadow: none !important;
}
/*
 * ANIMATED
 */
/* Navigation icon first level
.desktop-detected nav > ul > li > a:hover i {
  -webkit-animation-name: wobble;
  -moz-animation-name: wobble;
  -o-animation-name: wobble;
  animation-name: wobble;
}

.desktop-detected nav > ul > li > a:hover i {
  -webkit-animation-duration: .7s;
  -moz-animation-duration: .7s;
  -o-animation-duration: .7s;
  animation-duration: .7s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}*/
/* All dropdowns */
.open > .dropdown-menu {
  -webkit-animation-name: flipInX;
  -moz-animation-name: flipInX;
  -o-animation-name: flipInX;
  animation-name: flipInX;
  -webkit-animation-duration: .4s;
  -moz-animation-duration: .4s;
  -o-animation-duration: .4s;
  animation-duration: .4s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}
.active + .ajax-dropdown {
  -webkit-animation-name: flipInY;
  -moz-animation-name: flipInY;
  -o-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: .7s;
  -moz-animation-duration: .7s;
  -o-animation-duration: .7s;
  animation-duration: .7s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}
/*
   * USED WITH DEMO
   */
.square {
  display: block;
  float: left;
  margin-right: 10px;
  height: 20px;
  width: 20px;
}
/*
   * RESET BOOTSTRAP DEFAULT IE FILTER
   */
[class^="bg-"],
[class*=" bg-"] {
  filter: none !important;
}
/*
   * TEXT COLOR
   */
.txt-color-blue {
  color: #57889c !important;
}
.txt-color-blueLight {
  color: #92a2a8 !important;
}
.txt-color-blueDark {
  color: #4c4f53 !important;
}
.txt-color-green {
  color: #356e35 !important;
}
.txt-color-greenLight {
  color: #71843f !important;
}
.txt-color-greenDark {
  color: #496949 !important;
}
.txt-color-red {
  color: #a90329 !important;
}
.txt-color-yellow {
  color: #b09b5b !important;
}
.txt-color-orange {
  color: #b19a6b !important;
}
.txt-color-orangeDark {
  color: #a57225 !important;
}
.txt-color-pink {
  color: #ac5287 !important;
}
.txt-color-pinkDark {
  color: #a8829f !important;
}
.txt-color-purple {
  color: #6e587a !important;
}
.txt-color-darken {
  color: #404040 !important;
}
.txt-color-lighten {
  color: #d5e7ec !important;
}
.txt-color-white {
  color: #ffffff !important;
}
.txt-color-grayDark {
  color: #525252 !important;
}
.txt-color-magenta {
  color: #6e3671 !important;
}
.txt-color-teal {
  color: #568a89 !important;
}
.txt-color-redLight {
  color: #a65858 !important;
}
/*
   * BACKGROUNDS
   */
.bg-color-blue {
  background-color: #57889c !important;
}
.bg-color-blueLight {
  background-color: #92a2a8 !important;
}
.bg-color-blueDark {
  background-color: #4c4f53 !important;
}
.bg-color-green {
  background-color: #356e35 !important;
}
.bg-color-greenLight {
  background-color: #71843f !important;
}
.bg-color-greenDark {
  background-color: #496949 !important;
}
.bg-color-red {
  background-color: #a90329 !important;
}
.bg-color-yellow {
  background-color: #b09b5b !important;
}
.bg-color-orange {
  background-color: #c79121 !important;
}
.bg-color-orangeDark {
  background-color: #a57225 !important;
}
.bg-color-pink {
  background-color: #ac5287 !important;
}
.bg-color-pinkDark {
  background-color: #a8829f !important;
}
.bg-color-purple {
  background-color: #6e587a !important;
}
.bg-color-darken {
  background-color: #404040 !important;
}
.bg-color-lighten {
  background-color: #d5e7ec !important;
}
.bg-color-white {
  background-color: #ffffff !important;
}
.bg-color-grayDark {
  background-color: #525252 !important;
}
.bg-color-magenta {
  background-color: #6e3671 !important;
}
.bg-color-teal {
  background-color: #568a89 !important;
}
.bg-color-redLight {
  background-color: #a65858 !important;
}
.page-footer {
  height: 52px;
  padding: 15px 13px 0;
  padding-left: 233px;
  border-top: 1px solid #CECECE;
  background: #2a2725;
  width: 100%;
  position: absolute;
  display: block;
  bottom: 0px;
}
.fixed-page-footer .page-footer {
  position: fixed;
  z-index: 901;
}
.minified .page-footer {
  padding-left: 58px;
}
.container.fixed-page-footer .page-footer {
  max-width: 1164px;
}
.hidden-menu .page-footer {
  padding-left: 20px;
}
/*
 * GENERAL MOBILE (Applies to all resolutions below 979px)
 */
@media (max-width: 979px) {
  /* mobile lock x-scroll for menu */
  html.hidden-menu-mobile-lock {
    overflow-x: hidden;
  }
  /* Force content to spread 100% */
  #main {
    width: 100% !important;
  }
  /* Footer */
  .page-footer {
    padding: 15px 14px 0;
  }
  /* Profile Page */
  .profile-pic > img {
    width: 110px;
  }
  /* Profile Page */
  .profile-pic {
    float: left;
  }
  .profile-pic > img {
    margin-right: 10px;
  }
  .profile-pic + div,
  .profile-pic + div + div {
    padding-left: 30px;
    padding-right: 30px;
  }
  /* added new class per version 1.3
  html, body {
    overflow-x: hidden !important;
  }*/
  #fullscreen > :first-child > a {
    display: none !important;
  }
  body.container {
    border: none !important;
    width: 100% !important;
  }
  .inbox-data-message > :first-child {
    height: 50px;
    overflow: hidden;
  }
  .show-stats .show-stat-buttons > :first-child {
    padding-right: 13px;
  }
  .show-stats .show-stat-buttons > :last-child {
    padding-left: 13px;
  }
  .inbox-checkbox-triggered > .btn-group .btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  .inbox-body .table-wrap {
    padding: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .inbox-nav-bar {
    padding-left: 5px;
    padding-right: 5px;
  }
  .inbox-data-message > :first-child > :first-child {
    display: block !important;
    font-size: 14px;
  }
  .inbox-table-icon > :first-child {
    margin-top: 12px;
  }
  #inbox-table .inbox-table-icon {
    padding-left: 10px !important;
  }
  /* search header */
  .header-search.pull-right {
    margin-left: 0px;
  }
  /* Visibility */
  .visible-tablet {
    display: inline-block !important;
  }
  .hidden-tablet,
  .display-inline.hidden-tablet {
    display: none !important;
  }
  /* dropzone image fix */
  .dropzone .dz-default.dz-message {
    background-size: 100%;
    width: 200px;
    height: 43px;
    margin-left: -112px;
    margin-top: -23.5px;
  }
}
/* MOBILE LAYOUT STARTS HERE */
/*
 * IPAD HORIZONTAL
 */
@media (min-width: 768px) and (max-width: 979px) {
  #main::after {
    /*content: "IPAD HORIZONTAL";*/
  }
  #main {
    margin-left: 0;
  }
  #left-panel {
    left: -220px;
  }
  /* minified adjustment counter */
  .minified #left-panel {
    left: 0px;
  }
  /* responsive menu button */
  #hide-menu > :first-child > a {
    margin-top: 9px;
    width: 40px;
  }
  .btn-header a {
    margin-top: 9px !important;
    width: 40px !important;
  }
  #hide-menu i {
    color: #6D6A69;
    font-size: 100%;
  }
  .hidden-menu #hide-menu i {
    color: #ffffff;
  }
  #hide-menu i::before {
    content: "\f0c9";
  }
  .hidden-menu #left-panel {
    left: 0;
  }
  .hidden-menu #main {
    position: relative;
    left: 210px;
  }
  .hidden-menu.fixed-ribbon #ribbon {
    left: 220px;
    width: 100%;
  }
  .fixed-ribbon #ribbon {
    left: 0px;
  }
  /*
   * Header search
   */
  .header-search > input[type="text"] {
    margin-top: 9px;
  }
}
/*
 * IPAD VERTICAL
 */
@media (min-width: 768px) and (max-width: 880px) {
  #main::after {
    /*content: "IPAD VERTICAL";*/
  }
  #main {
    margin-left: 0;
    overflow-x: hidden;
  }
  #left-panel {
    left: -220px;
  }
  .no-content-padding {
    margin: 0 -14px !important;
  }
  .inbox-nav-bar.no-content-padding {
    margin-top: -10px !important;
  }
  /* minified adjustment counter */
  .minified #left-panel {
    left: 0px;
  }
  /* responsive menu button */
  #hide-menu > :first-child > a,
  .btn-header a {
    margin-top: 5px !important;
    width: 40px !important;
    height: 39px !important;
    line-height: 38px !important;
  }
  #logo {
    margin-top: 10px;
  }
  #logo img {
    width: 127px;
  }
  /* transparent header button */
  .btn-header.transparent a {
    border: none !important;
    background: none;
    margin-left: 0;
    width: 25px !important;
    box-shadow: none !important;
  }
  .btn-header.transparent a:hover {
    color: #a90329;
  }
  #hide-menu i {
    color: #6D6A69;
    font-size: 121%;
  }
  .hidden-menu #hide-menu i {
    color: #ffffff;
  }
  #hide-menu i::before {
    content: "\f0c9";
  }
  .hidden-menu #left-panel {
    left: 0px;
  }
  .hidden-menu #main {
    position: relative;
    left: 210px;
  }
  .hidden-menu.fixed-ribbon #ribbon {
    left: 220px;
    width: 100%;
  }
  .fixed-ribbon #ribbon {
    left: 0px;
  }
  /*
   * Header search
   */
  .header-search > input[type="text"] {
    margin-top: 9px;
  }
}
/*
 * OTHER DEVICES
 */
@media (max-width: 767px) {
  /* Profile Page */
  .profile-pic > img {
    width: 100px;
  }
  #main::after {
    /*content: "OTHER DEVICES";*/
  }
  #main {
    margin-left: 0px;
    overflow-x: hidden;
  }
  #left-panel {
    left: -220px;
  }
  /* dashboard microcharts */
  .show-stat-microcharts > div {
    margin-top: 0px !important;
    border-right: none !important;
  }
  .show-stat-buttons {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  /* padding adjustment */
  #content,
  #ribbon {
    padding-left: 5px;
    padding-right: 5px;
  }
  #header {
    padding-right: 5px;
  }
  #logo {
    margin-left: 4px;
  }
  .no-content-padding {
    margin: 0 -5px !important;
  }
  .inbox-nav-bar.no-content-padding {
    margin-top: -10px !important;
  }
  /* minified adjustment counter */
  .minified #left-panel {
    left: 0px;
  }
  /* transparent header button */
  .btn-header.transparent a {
    border: none !important;
    background: none;
    margin-left: 0;
    width: 25px !important;
    box-shadow: none !important;
  }
  .btn-header.transparent a:hover {
    color: #a90329;
  }
  /* responsive menu button */
  #hide-menu > :first-child > a,
  .btn-header a {
    margin-top: 5px !important;
    width: 40px !important;
    height: 39px !important;
    line-height: 38px !important;
  }
  #hide-menu > :first-child > a {
    width: 50px !important;
  }
  #logo {
    margin-top: 10px;
  }
  #logo img {
    width: 127px;
  }
  #hide-menu i {
    color: #6D6A69;
    font-size: 121%;
  }
  .hidden-menu #hide-menu i {
    color: #ffffff;
  }
  #hide-menu i::before {
    content: "\f0c9";
  }
  .hidden-menu #left-panel {
    left: 0px;
  }
  .hidden-menu #main {
    position: relative;
    left: 210px;
  }
  .hidden-menu.fixed-ribbon #ribbon {
    left: 220px;
    width: 100%;
  }
  .fixed-ribbon #ribbon {
    left: 0px;
  }
  /*.fixed-navigation #left-panel {
    position:absolute !important;
  }*/
  body.fixed-navigation.hidden-menu {
    overflow: hidden !important;
  }
  /*
   * fixed ribbon
   */
  .fixed-ribbon #content {
    padding-top: 52px;
  }
  /*
   * header search
   */
  .header-search {
    display: none;
  }
  #search-mobile {
    display: block;
  }
  .search-mobile .header-search {
    display: block;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 49px;
    background: #333;
    padding: 0 2px;
    box-sizing: border-box;
    left: 0px;
  }
  .search-mobile .header-search > input[type="text"] {
    margin-top: 2px;
    height: 45px;
    border-color: #333;
    padding-right: 75px;
  }
  .search-mobile .header-search > button {
    height: 29px;
    line-height: 29px;
    background: #DDDDDD;
  }
  .search-mobile .header-search > button {
    right: 44px;
  }
  .search-mobile #search-mobile {
    display: none;
  }
  .search-mobile #cancel-search-js {
    display: block;
  }
}
/*
 * ANDROID
 */
@media only screen and (min-width: 0px) and (max-width: 679px) {
  /* Profile Page */
  .profile-pic > img {
    width: 90px;
  }
  #main::after {
    /*content: "ANDROID";*/
  }
  #main {
    margin-left: 0;
    overflow-x: hidden;
  }
  #left-panel {
    left: -220px;
  }
  /* dashboard microcharts */
  .show-stat-microcharts > div {
    margin-top: 0px !important;
    border-right: none !important;
  }
  .show-stat-buttons {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  /* padding adjustment */
  #content,
  #ribbon {
    padding-left: 5px;
    padding-right: 5px;
  }
  #header {
    padding-right: 5px;
  }
  #logo {
    margin-left: 4px;
  }
  .no-content-padding {
    margin: 0 -5px !important;
  }
  .inbox-nav-bar.no-content-padding {
    margin-top: -10px !important;
  }
  /* minified adjustment counter */
  .minified #left-panel {
    left: 0px;
  }
  /* header color altered and adjusted for mobile view & contrast */
  #header {
    background: #ffffff;
  }
  #ribbon {
    border-bottom: 1px solid #CFCFCF;
    border-top: 1px solid #E6E6E6;
    background: #F5F5F5;
  }
  #ribbon .breadcrumb a,
  #ribbon .breadcrumb {
    color: #6D6C6C !important;
  }
  #ribbon .breadcrumb > .active,
  #ribbon .breadcrumb li:last-child {
    color: #333 !important;
  }
  /* responsive menu button */
  #hide-menu > :first-child > a,
  .btn-header a {
    margin-top: 5px !important;
    width: 40px !important;
    height: 39px !important;
    line-height: 38px !important;
  }
  #hide-menu > :first-child > a {
    width: 50px !important;
  }
  #logo {
    margin-top: 10px;
  }
  #logo img {
    width: 127px;
  }
  /* transparent header button */
  .btn-header.transparent a {
    border: none !important;
    background: none;
    margin-left: 0;
    width: 25px !important;
    box-shadow: none !important;
  }
  .btn-header.transparent a:hover {
    color: #a90329;
  }
  /* menu btn cont... */
  #hide-menu i {
    color: #6D6A69;
    font-size: 121%;
  }
  .hidden-menu #hide-menu i {
    color: #ffffff;
  }
  #hide-menu i::before {
    content: "\f0c9";
  }
  .hidden-menu #left-panel {
    left: 0;
  }
  .hidden-menu #main {
    position: relative;
    left: 210px;
  }
  .hidden-menu.fixed-ribbon #ribbon {
    left: 220px;
    width: 100%;
  }
  .fixed-ribbon #ribbon {
    left: 0px;
  }
  /*
   * header search
   */
  .header-search {
    display: none;
  }
  #search-mobile {
    display: block;
  }
  .search-mobile .header-search {
    display: block;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 49px;
    background: #333;
    padding: 0 2px;
    box-sizing: border-box;
    left: 0px;
  }
  .search-mobile .header-search > input[type="text"] {
    margin-top: 2px;
    height: 45px;
    border-color: #333;
    padding-right: 75px;
  }
  .search-mobile .header-search > button {
    height: 29px;
    line-height: 29px;
    background: #DDDDDD;
  }
  .search-mobile .header-search > button {
    right: 44px;
  }
  .search-mobile #search-mobile {
    display: none;
  }
  .search-mobile #cancel-search-js {
    display: block;
  }
  .hidden-tablet {
    display: none !important;
  }
}
/*
 * IPHONE
 */
@media only screen and (min-width: 320px) and (max-width: 479px) {
  /* Hide headings on phones as its not needed*/
  .page-title {
    display: none;
  }
  /* Profile Page */
  .profile-pic {
    text-align: left;
  }
  .profile-pic > img {
    width: 90px;
    margin-left: 0px;
    top: -25px;
    margin-right: 0px;
  }
  .profile-pic + div > h1 {
    font-size: 20px;
  }
  .profile-pic + div > h1 small {
    font-size: 16px;
  }
  #main::after {
    /*content: "IPHONE";*/
  }
  #main {
    margin-left: 0;
    overflow-x: hidden;
  }
  #left-panel {
    left: -220px;
  }
  /* dashboard microcharts */
  .show-stat-microcharts > div {
    margin-top: 0px !important;
    border-right: none !important;
  }
  .show-stat-buttons {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  /* padding adjustment */
  #content,
  #ribbon {
    padding-left: 5px;
    padding-right: 5px;
  }
  #header {
    padding-right: 5px;
  }
  #logo {
    margin-left: 4px;
  }
  .no-content-padding {
    margin: 0 -5px !important;
  }
  .inbox-nav-bar.no-content-padding {
    margin-top: -10px !important;
  }
  /* minified adjustment counter */
  .minified #left-panel {
    left: 0px;
  }
  /* header color altered and adjusted for mobile view & contrast */
  #header {
    background: #ffffff;
  }
  #ribbon {
    border-bottom: 1px solid #CFCFCF;
    border-top: 1px solid #E6E6E6;
    background: #F5F5F5;
  }
  #ribbon .breadcrumb a,
  #ribbon .breadcrumb {
    color: #6D6C6C !important;
  }
  #ribbon .breadcrumb > .active,
  #ribbon .breadcrumb li:last-child {
    color: #333 !important;
  }
  /* lego width adjustment */
  #logo {
    width: 135px;
  }
  #logo-group {
    width: 169px !important;
  }
  /* spark line top */
  #sparks {
    text-align: center;
    background: #E0E0E0;
    padding: 10px 0;
    margin-bottom: 15px;
  }
  #sparks li {
    padding: 0 10px 0 20px;
  }
  /* responsive menu button */
  #hide-menu > :first-child > a,
  .btn-header a {
    margin-top: 5px !important;
    width: 40px !important;
    height: 39px !important;
    line-height: 38px !important;
  }
  #logo {
    margin-top: 10px;
  }
  #logo img {
    width: 127px;
  }
  /* transparent header button */
  .btn-header.transparent a {
    border: none !important;
    background: none;
    margin-left: 0;
    width: 25px !important;
    box-shadow: none !important;
  }
  .btn-header.transparent a:hover {
    color: #a90329;
  }
  #hide-menu > :first-child > a {
    width: 46px !important;
  }
  #hide-menu i {
    color: #6D6A69;
    font-size: 121%;
  }
  .hidden-menu #hide-menu i {
    color: #ffffff;
  }
  #hide-menu i::before {
    content: "\f0c9";
  }
  .hidden-menu #left-panel {
    left: 0px;
  }
  .hidden-menu #main {
    position: relative;
    left: 210px;
  }
  .hidden-menu.fixed-ribbon #ribbon {
    left: 220px;
    width: 100%;
  }
  .fixed-ribbon #ribbon {
    left: 0px;
  }
  /* #*$^& Bootstrap 3 ! */
  .hidden-mobile {
    display: none !important;
  }
  .visible-mobile {
    display: inline-block !important;
  }
  /* activity panel */
  .ajax-notifications {
    height: 250px;
  }
  .ajax-dropdown {
    width: 299px;
    height: 320px;
    left: 0;
    top: 49px;
  }
  .ajax-dropdown:before {
    margin-left: -14px;
  }
  .ajax-dropdown:after {
    margin-left: -13px;
  }
  /*
   * header search
   */
  .header-search {
    display: none;
  }
  #search-mobile {
    display: block;
    margin-left: 0;
  }
  .search-mobile .header-search {
    display: block;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 49px;
    background: #333;
    padding: 0 2px;
    box-sizing: border-box;
    left: 0px;
  }
  .search-mobile .header-search > input[type="text"] {
    margin-top: 2px;
    height: 45px;
    border-color: #333;
    padding-right: 75px;
  }
  .search-mobile .header-search > button {
    height: 29px;
    line-height: 29px;
    background: #DDDDDD;
  }
  .search-mobile .header-search > button {
    right: 44px;
  }
  .search-mobile #search-mobile {
    display: none;
  }
  .search-mobile #cancel-search-js {
    display: block;
  }
}
#mobile-profile-img {
  display: none !important;
  padding-right: 2px !important;
  padding-left: 2px !important;
}
.menu-on-top #mobile-profile-img {
  display: block !important;
}
#mobile-profile-img a.userdropdown img {
  width: 30px;
  margin-top: 5px;
  margin-left: 2px;
  border-radius: 3px;
  border: 1px solid #797979 !important;
}
.menu-on-top .page-footer {
  padding: 15px 13px 0;
}
.menu-on-top.hidden-menu #left-panel {
  left: 0px;
}
.menu-on-top #main {
  margin-left: 0px !important;
  margin-top: 69px !important;
}
.menu-on-top .minifyme,
.menu-on-top #hide-menu,
.menu-on-top #logout {
  display: none !important;
}
.menu-on-top aside#left-panel {
  width: 100% !important;
  min-height: 0 !important;
  height: auto;
  overflow: visible;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid #222;
  border-top: 1px solid #333;
}
.menu-on-top aside#left-panel nav > ul {
  display: block;
  width: 100%;
}
.menu-on-top aside#left-panel nav > ul > li:first-child {
  margin-left: 0px;
}
.menu-on-top aside#left-panel nav > ul > li {
  display: block;
  position: relative;
  height: auto !important;
  padding: 0px !important;
  display: inline-block;
  float: left;
  border-right: 1px solid #222;
  border-left: 1px solid #4E4E4E;
  overflow: visible;
}
.menu-on-top aside#left-panel nav > ul > li > a {
  display: inline-block;
  height: 68px;
  line-height: 17px;
  text-shadow: none !important;
  font-size: 13px;
  text-decoration: none;
  line-height: 22px;
  padding: 10px 9px !important;
}
.menu-on-top li.active > a {
  font-weight: bold !important;
}
.menu-on-top .login-info,
.menu-on-top nav > ul > li > a b {
  display: none;
}
.menu-on-top aside#left-panel nav > ul > li > a > i {
  display: block !important;
  margin: 1px 0 4px;
  line-height: inherit;
  width: auto;
  /*vertical-align: sub;*/
  text-align: center;
  font-size: 24px;
  margin-bottom: 2px;
  width: 100%;
}
.menu-on-top .menu-item-parent {
  max-width: 72px;
  min-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 13px;
}
.menu-on-top .menu-item-parent + span {
  float: none !important;
  top: 5px;
  right: 5px;
  position: absolute;
}
.menu-on-top nav ul ul li a {
  padding-left: 10px !important;
  font-size: 13px;
}
/* Hover state */
.menu-on-top aside#left-panel nav > ul > li:hover {
  background: #ffffff;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #ffffff), color-stop(1, #cfcfcf));
  background: -ms-linear-gradient(bottom, #ffffff, #cfcfcf);
  background: -moz-linear-gradient(center bottom, #ffffff 0%, #cfcfcf 100%);
  background: -o-linear-gradient(#cfcfcf, #ffffff);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cfcfcf', endColorstr='#ffffff', GradientType=0);
}
.menu-on-top aside#left-panel nav > ul > li:hover > a {
  color: #333 !important;
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.menu-on-top aside#left-panel nav > ul > li > ul {
  position: absolute !important;
  background: #3a3633;
}
.menu-on-top aside#left-panel nav > ul > li:hover > ul {
  display: block !important;
}
.menu-on-top aside#left-panel nav ul li.active > a:before {
  content: "" !important;
}
.menu-on-top nav > ul ul li::before,
.menu-on-top nav > ul > li > ul::before {
  border: none;
}
/* hover state universal */
.menu-on-top nav ul ul li a,
.menu-on-top nav ul ul ul li a {
  color: #4C4F53;
}
.menu-on-top nav ul ul li a:hover,
.menu-on-top nav ul ul ul li a:hover {
  color: #fff;
  background-color: #4C4F53;
}
.menu-on-top nav ul ul li:hover > a {
  color: #fff;
  background-color: #4C4F53;
}
.menu-on-top nav > ul ul ul {
  border-top: 1px solid #c7c7c7 !important;
}
/* Hover state second level */
.menu-on-top nav ul ul li {
  overflow: visible;
}
.menu-on-top nav ul ul,
.menu-on-top nav ul ul ul {
  display: none !important;
  background: #ffffff !important;
  box-shadow: none;
  border: 1px solid #c7c7c7;
  -webkit-box-shadow: inset 0px -4px 0px 0px #bd2a2a;
  -moz-box-shadow: inset 0px -4px 0px 0px #bd2a2a;
  box-shadow: inset 0px -4px 0px 0px #bd2a2a;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border-bottom: 1px solid #BD2A2A;
  border-top: none;
  width: 200px;
  border-radius: 0;
  padding: 3px;
  margin-left: 0px;
}
.menu-on-top nav > ul ul li:hover > ul {
  display: block !important;
  position: absolute;
  z-index: 999;
  left: 100%;
  top: -8px;
  background: #333;
}
/*
 * Child Arrow
 */
/* top level childs */
.menu-on-top nav > ul > li > a:after {
  content: "\f0dd" !important;
  top: 76% !important;
  color: #636363 !important;
  left: 46%;
}
/* next level childs */
.menu-on-top nav > ul > li > a:after,
.menu-on-top nav > ul ul > li a:after,
.menu-on-top nav ul ul li:hover > a:after,
.menu-on-top nav > ul ul > li a:hover:after {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0da";
  display: block !important;
  position: absolute;
  top: 9px;
  right: 4px;
  color: #333;
}
.menu-on-top nav ul ul li:hover > a:after,
.menu-on-top nav > ul ul > li a:hover:after {
  color: #fff;
}
.menu-on-top nav > ul ul > li a:only-child:after,
.menu-on-top nav > ul > li > a:only-child:after {
  content: "" !important;
}
/*
 * Active Child
 */
.menu-on-top nav ul ul .active > a {
  color: #333 !important;
}
.menu-on-top nav ul ul .active > a:hover,
.menu-on-top nav ul ul li.active:hover > a {
  color: #fff !important;
}
/*
 * SLIMSCROLL CSS CHANGE
 */
.menu-on-top .slimScrollDiv,
.menu-on-top nav {
  overflow: visible !important;
}
/*
 * FIXED TOP HEADER
 */
body.menu-on-top.fixed-header {
  padding-top: 49px;
}
/*
 * FIXED TOP HEADER + NAV
 */
body.menu-on-top.fixed-header.fixed-navigation nav > ul {
  padding-right: 0px;
  /**/
  position: relative;
  /**/
  height: auto;
  /**/
  width: auto;
  /**/
  overflow: visible;
  /**/
}
/*
 * FIXED TOP HEADER + NAV + RIBBON
 */
body.menu-on-top.fixed-header.fixed-navigation.fixed-ribbon #ribbon {
  left: 0px;
  top: 118px;
}
/*
 * RESPONSIVE
 */
/*
 * GENERAL MOBILE (Applies to all resolutions below 979px)
 */
@media (max-width: 979px) {
  .menu-on-top #mobile-profile-img a.userdropdown img {
    width: 39px;
    margin-top: 0px;
    margin-left: 2px;
    border-radius: 3px;
    margin-right: -3px;
  }
  .menu-on-top .btn-header.pull-right {
    margin-left: 0px !important;
  }
  .menu-on-top #main {
    margin-top: 0px !important;
  }
  .menu-on-top #left-panel {
    left: 0px;
    display: none;
  }
  body.menu-on-top.fixed-header.fixed-navigation.fixed-ribbon #ribbon {
    top: 49px;
  }
  .menu-on-top.hidden-menu #left-panel {
    display: block;
  }
  .menu-on-top.hidden-menu #main {
    left: 0px !important;
  }
  .menu-on-top #hide-menu {
    display: block !important;
  }
  .menu-on-top aside#left-panel nav {
    height: 300px !important;
    overflow: scroll;
    overflow-x: hidden !important;
    -webkit-overflow-scrolling: touch;
  }
  .menu-on-top .menu-item-parent {
    white-space: inherit;
    overflow: visible;
    text-overflow: inherit;
    text-align: left;
    min-width: 85%;
    max-width: 85%;
    padding-left: 5px;
  }
  .menu-on-top .menu-item-parent + span {
    top: 50%;
    margin-top: -8px;
  }
  .menu-on-top nav li.active > ul {
    display: block;
  }
  .menu-on-top nav > ul > li.active {
    background: #fff;
    background: -moz-linear-gradient(top, #cfcfcf 0, #ffffff 66%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #cfcfcf), color-stop(66%, #ffffff));
    background: -webkit-linear-gradient(top, #cfcfcf 0, #ffffff 66%);
    background: -o-linear-gradient(top, #cfcfcf 0, #ffffff 66%);
    background: -ms-linear-gradient(top, #cfcfcf 0, #ffffff 66%);
    background: linear-gradient(to bottom, #cfcfcf 0, #ffffff 66%);
  }
  .menu-on-top nav > ul > li.active > a {
    color: #333 !important;
  }
  .menu-on-top nav ul ul li:hover > a:after,
  .menu-on-top nav > ul ul > li a:after,
  .menu-on-top nav > ul ul > li a:hover:after,
  .menu-on-top nav > ul > li > a:after {
    top: 50%;
    right: 15px;
    font-size: 15px;
    margin-top: -8px;
    content: "\f0dd" !important;
  }
  .menu-on-top nav > ul ul > li a:only-child:after,
  .menu-on-top nav > ul > li > a:only-child:after {
    content: "" !important;
  }
  .menu-on-top nav > ul > li > a:after {
    top: 50% !important;
    left: auto;
    margin-top: -14px;
    font-size: 20px;
  }
  .menu-on-top nav ul ul,
  .menu-on-top nav ul ul ul {
    padding: 0px;
    border: none;
  }
  .menu-on-top nav ul ul ul {
    border-bottom: 2px solid #333;
  }
  .menu-on-top aside#left-panel nav > ul > li {
    width: 100%;
  }
  .menu-on-top aside#left-panel nav > ul > li > a {
    height: auto;
    display: block;
    padding: 8px 9px !important;
    border-bottom: 1px dotted #585858;
  }
  .menu-on-top nav > ul > li > ul > li > a,
  .menu-on-top nav > ul > li > ul > li > ul > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .menu-on-top aside#left-panel nav > ul > li > a > i {
    display: inline !important;
  }
  /* menu child */
  .menu-on-top aside#left-panel nav > ul li ul {
    position: relative !important;
    width: 100%;
    top: 0px;
    left: 0px;
  }
  .menu-on-top aside#left-panel nav > ul > li:active > ul,
  .menu-on-top aside#left-panel nav > ul > li:focus > ul {
    display: block !important;
  }
}
/*
 * Menu on top invisible
 * applying this class will make these elements invisible
 * when menu on top is enabled
 */
.menu-on-top .top-menu-invisible {
  display: none !important;
}
.no-menu #left-panel,
.no-menu #hide-menu,
.no-menu #logout {
  display: none !important;
}
.no-menu #main {
  margin: 0px !important;
}
.no-menu #mobile-profile-img {
  display: block !important;
}
.no-menu #mobile-profile-img a.userdropdown img {
  width: 30px;
  margin-top: 5px;
  margin-left: 2px;
  border-radius: 3px;
  border: 1px solid #797979!important;
}
